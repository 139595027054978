<template>
  <b-card :key="gotPlant ? plant.id : 0" class="plant-info max-w-xl" noBody @click="clickx">
    <!-- Photo de garde -->
    <div v-if="gotPlant" class="jaya-top-row p-1 ">
      <swiper v-if="plantPhotos.length > 1" ref="swiper" :options="plantImageSwiperOption">
        <swiper-slide v-for="(photo, index) in plantPhotos" :key="index" class="d-flex justify-content-center w-100">
          <viewer ref="viewer" :images="[photo]" class="jaya-container">
            <img v-for="src in [photo]"
                :key="src"
                :alt="getAlt(plant.photos[index].author, plant.photos[index].license)"
                :src="src"
                class="jaya-image">
          </viewer>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination top-0"/>
      </swiper>
      <viewer v-else :images="plantPhotos" class="jaya-container">
        <img v-for="src in plantPhotos"
            :key="src"
            :alt="getAlt(plant.photos[0] ? plant.photos[0].author : null, plant.photos[0] ? plant.photos[0].license : null)"
            :src="src"
            class="jaya-image">
      </viewer>

      <div class="jaya-image-buttons">
        <b-button
            v-if="!isInGarden && !plantPage"
            class="ml-1"
            variant="primary"
            @click="checkInvasiveStatus(plant.id)">
          Ajouter au jardin
        </b-button>
        <b-button
            class="ml-1 p-50"
            pill
            size="sm"
            variant="primary"
            @click="copyLink">
          <feather-icon
              icon="Link2Icon"
              size="24"/>
        </b-button>
        <b-button
            v-if="(isInGarden)"
            class="ml-1 p-50"
            pill
            size="sm"
            variant="primary"
            @click="checkInvasiveStatus(plant.id)">
          <feather-icon
              icon="PlusIcon"
              size="24"/>
        </b-button>
        <b-button
            v-if="(isInGarden)"
            class="ml-1 p-50"
            pill
            size="sm"
            variant="danger"
            @click="deletePlant(gardenPlantId, plant.id)">
          <feather-icon
              icon="TrashIcon"
              size="24"/>
        </b-button>
      </div>

      <div class="jaya-image-edit-buttons">
        <b-button
            v-if="user && user.is_superuser"
            class="ml-50 p-50"
            pill
            size="sm"
            style="position: relative;"
            variant="flat-primary"
            @click="addImage">
          <feather-icon
              icon="ImageIcon"
              size="24"/>
          <strong style="font-size: 22px;">+</strong>
        </b-button>
        <b-button
            v-if="plant.photos.length > 0 && user && user.is_superuser"
            class="ml-50 p-50"
            pill
            size="sm"
            variant="flat-primary"
            @click="editImage">
          <feather-icon
              icon="EditIcon"
              size="24"/>
        </b-button>
        <b-button
            v-if="plantPhotos.length > 1"
            class="ml-50 p-50"
            pill
            size="sm"
            variant="flat-primary"
            @click="showImageViewer">
          <feather-icon
              icon="MaximizeIcon"
              size="24"/>
        </b-button>
      </div>
    </div>

    <b-card-body v-if="gotPlant">
      <b-row class="mb-1">
        <div class="ml-1">
          <span class="font-medium-5 font-weight-bolder"> {{ plant.common_name }} </span><br>
          <i class="font-medium-2"> {{ plant.scientific_name }} </i> <br>
        </div>
      </b-row>

      <b-row>
        <b-alert
            v-if="plant.invasive"
            class="py-1 m-1 w-100"
            show
            variant="danger">
          <b-row class="text-center align-items-center px-1">
            <b-col cols="2">
              <img alt="Icon negatif" src="@/assets/images/icons/other/invasive.svg" style="height: 50px;">
            </b-col>
            <b-col>
              Attention cette plante est considérée comme envahissante.
            </b-col>
          </b-row>
        </b-alert>

        <b-alert
            v-if="plant.local"
            class="py-1 m-1 w-100"
            show
            variant="primary">
          <b-row class="text-center align-items-center px-1">
            <b-col cols="2">
              <img alt="Icon plant local" src="@/assets/images/icons/other/local.svg" style="height: 50px;">
            </b-col>
            <b-col>
              Cette plante est
              <JayaTooltip
                  text="Les listes de plantes indigènes sont fournies par les collectivités territoriales partenaires.">
                indigène
              </JayaTooltip>
              à votre territoire, c'est un atout pour la biodiversité de votre jardin.
            </b-col>
          </b-row>
        </b-alert>
        <b-alert
            v-if="$store.state.plant.residencePlants.filter(el => {return el == plant.common_name}).length"
            class="py-1 m-1 w-100"
            show
            variant="primary">
          <b-row class="text-center align-items-center px-1">
            <b-col cols="2">
              <img alt="Icon plant local" src="@/assets/images/icons/other/location.svg" style="height: 50px;">
            </b-col>
            <b-col>
              Cette plante est présente sur les surfaces végétalisées de votre résidence.
            </b-col>
          </b-row>
        </b-alert>

        <b-alert
            v-if="plant.adapted_to_balcony"
            class="text-center py-1 m-1 w-100"
            show
            variant="primary">
          Cette plante est
          <JayaTooltip text="Elle résiste bien à la sécheresse et/ou ses racines n'ont pas besoin de beaucoup de volume de sol.
                     D'autres specificités d'un balcon (présence de vent accentuée, plus grande inertie thermique,...) ne sont pas prises en compte par ce critère. ">
            adaptée
          </JayaTooltip>
          aux balcons et à la culture en pot.
        </b-alert>
      </b-row>

      <b-button
          v-if="plant.jaya_infos"
          class="mx-auto mb-1 d-flex align-items-center py-50"
          variant="outline-dark"
          @click="$refs.goToInteractionsPrompt.show()">
        <img alt="interaction" class="mr-50" src="@/assets/images/icons/other/interaction_very_good.svg"
            style="height: 28px;">
        <span class="align-middle">Cultures associées</span>
      </b-button>

      <b-alert
          v-if="plantInfoCount < 2"
          class="text-center py-1 m-1"
          show
          variant="warning">
        Ne disposant pas assez d'informations nous ne proposons pas la fiche de cette plante pour le moment.
      </b-alert>

      <div v-else class="w-100">
        <TextBlock v-if="plant.rf_infos && plant.rf_infos.culture_interrest" collapsable title="Intérêt">
          <feather-icon
              slot="titleSpace"
              icon="CheckIcon"
              size="24"/>
          <p class="text-justify"> {{ plant.rf_infos.culture_interrest }}</p>
        </TextBlock>

        <TextBlock v-if="isMaintenance" collapsable title="Entretien">
          <img slot="titleSpace" alt="Icone pelle" class="icon" src="@/assets/images/icons/tools/shovel.svg">

          <div v-if="plant.rf_infos && plant.rf_infos.watering[0]">
            <h4 class="d-flex">
              <img alt="Icon arrosage" class="icon-small" src="@/assets/images/icons/tools/watering_can.svg">
              Arrosage
            </h4>
            <p class="text-justify pr-1">{{ plant.rf_infos.watering[0].details }}</p>
          </div>

          <div v-if="isTrims">
            <h4 class="d-flex">
              <img alt="Icon taille" class="icon-small" src="@/assets/images/icons/tools/shear.svg">
              Taille
            </h4>
            <div v-for="(trim, indexTrim) in plant.rf_infos.trims" v-if="isTrims && isMonthsCorrect(trim.periods)"
                :key="indexTrim">
              <strong> {{ trim.type }} </strong>
              <br>
              <p v-if="trim.type === 'Taille sanitaire'" class="mb-50">
                Supprimer les branches mortes ainsi que celles présentant des anomalies de croissance
                ou des blessures importantes. A faire en fonction des besoins.
              </p>
              <JayaTimeline v-else :activeMonths="trim.periods |  numericMonthsArray" class="max-w-lg mb-50"/>
            </div>
          </div>

          <div v-if="isHarvests">
            <h4 class="d-flex">
              <img alt="Icon récolte" class="icon-small" src="@/assets/images/icons/tools/basket.svg">
              Récolte
            </h4>

            <div v-if="plant.rf_infos && plant.rf_infos.organs.length > 0">
              <div v-for="(organ, index) in plant.rf_infos.organs" v-if="isMonthsCorrect(organ.harvest_periods)"
                  :key="index">
                <strong> {{ organ.name }} </strong>
                <JayaTimeline :activeMonths="organ.harvest_periods |  numericMonthsArray" class="max-w-lg"/>
              </div>
            </div>

            <div v-else>
              <JayaTimeline v-if="plant.usda_infos" :activeMonths="plant.usda_infos.fruit_seed_period"
                  class="max-w-lg"/>
            </div>
          </div>
        </TextBlock>

        <TextBlock v-if="plant.rf_infos && plant.rf_infos.culture_interrest" collapsable title="Conditions optimales">
          <img slot="titleSpace" alt="Icone transplantoir" class="icon"
              src="@/assets/images/icons/tools/garden_trowel.svg">
          <b-list-group flush>
            <b-list-group-item
                v-if="this.temperature"
                class="d-flex justify-content-between">
              <strong>Temperature minimum</strong>
              {{ this.temperature }}
            </b-list-group-item>
            <b-list-group-item
                v-if="this.shade"
                class="d-flex justify-content-between">
              <strong>Ensoleillement minimum</strong>
              {{ this.shade }}
            </b-list-group-item>
            <b-list-group-item
                v-if="this.fertility"
                class="d-flex justify-content-between">
              <strong>Besoin en matière organique</strong>
              {{ this.fertility }}
            </b-list-group-item>
            <b-list-group-item
                v-if="plant.rf_infos && plant.rf_infos.humidities.length > 0"
                class="d-flex justify-content-between">
              <strong>Humidité</strong>
              <div>
              <span v-for="(humidity, indexHumidity) in plant.rf_infos.humidities" :key="indexHumidity">
                <span v-if="indexHumidity">/</span>
                {{ humidity }}
              </span>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="soilTexture.length > 0">
              <strong>Texture du sol</strong>
              <div
                  v-if="soilTexture.length > 0"
                  class="d-flex justify-content-start align-items-center">
                <feather-icon
                    v-if="soilTexture.includes('fine')"
                    class="text-primary"
                    icon="CheckIcon"/>
                <feather-icon
                    v-else
                    class="text-danger"
                    icon="XIcon"/>
                Fin
              </div>

              <div
                  v-if="soilTexture.length > 0"
                  class="d-flex justify-content-start align-items-center">
                <feather-icon
                    v-if="soilTexture.includes('medium')"
                    class="text-primary"
                    icon="CheckIcon"/>
                <feather-icon
                    v-else
                    class="text-danger"
                    icon="XIcon"/>
                Moyen
              </div>

              <div
                  v-if="soilTexture.length > 0"
                  class="d-flex justify-content-start align-items-center">
                <feather-icon
                    v-if="soilTexture.includes('coarse')"
                    class="text-primary"
                    icon="CheckIcon"/>
                <feather-icon
                    v-else
                    class="text-danger"
                    icon="XIcon"/>
                Grossier
              </div>
            </b-list-group-item>
          </b-list-group>
        </TextBlock>

        <TextBlock v-if="isCharacteristics" collapsable title="Caractéristiques">
          <img slot="titleSpace" alt="Icone hauteur des plantes" class="icon"
              src="@/assets/images/icons/other/plant_height.svg">

          <b-list-group flush>
            <b-list-group-item
                v-if="this.category"
                class="d-flex justify-content-between">
              <strong>Catégorie</strong>
              {{ this.category }}
            </b-list-group-item>
            <b-list-group-item
                v-if="this.duration"
                class="d-flex justify-content-between">
              <strong>Temporalité</strong>
              {{ this.duration }}
            </b-list-group-item>
            <b-list-group-item
                v-if="this.plant.usda_infos"
                class="d-flex justify-content-between">
              <strong>Cotylédon</strong>
              {{ this.plant.usda_infos.category }}
            </b-list-group-item>
            <b-list-group-item
                v-if="this.height"
                class="d-flex justify-content-between">
              <strong>Hauteur à maturité</strong>
              {{ this.height }}
            </b-list-group-item>
            <b-list-group-item
                v-if="this.rootDepth"
                class="d-flex justify-content-between">
              <strong>Profondeur des racines</strong>
              {{ this.rootDepth }}
            </b-list-group-item>
            <b-list-group-item
                v-if="this.flowerColor"
                class="d-flex justify-content-between">
              <strong>Couleur des fleur</strong>
              {{ this.flowerColor }}
            </b-list-group-item>
          </b-list-group>

          <div
              v-if="plant.usda_infos && plant.usda_infos.active_growth_period.length > 0 && plant.usda_infos.active_growth_period.length < 12"
              class="mb-1">
            <h4 class="d-flex">
              <img alt="Icon croissance des plantes" class="icon-small"
                  src="@/assets/images/icons/other/growth_period.svg">
              Croissance
            </h4>
            <JayaTimeline :activeMonths="plant.usda_infos.active_growth_period" class="max-w-lg"/>
          </div>

          <div v-if="plant.rf_infos && plant.rf_infos.flowering_periods.length > 0" class="mb-1">
            <h4 class="d-flex">
              <img alt="Icon floraison" class="icon-small" src="@/assets/images/icons/plants/default_flower.svg">
              Floraison
            </h4>
            <JayaTimeline :activeMonths="plant.rf_infos.flowering_periods | numericMonthsArray" class="max-w-lg"/>
          </div>
        </TextBlock>

        <TextBlock
            v-if="(plant.rf_infos && plant.rf_infos.biodiversity.length > 0) || plant.pollinators.length > 0 || plant.host_for.length > 0"
            collapsable title="Biodiversité">
          <img slot="titleSpace" alt="Icone hauteur des plantes" class="icon"
              src="@/assets/images/icons/fauna/butterfly.svg">

          <div v-if="plant.rf_infos && plant.rf_infos.biodiversity.length > 0">
            <p v-for="(biodiversity, index) in plant.rf_infos.biodiversity" :key="index">
              <span v-html="biodiversity.benefic_effect"/> pour les
              <span v-html="biodiversity.concerned_spicies.toLowerCase()"/><br>
            </p>
          </div>

          <div v-else>
            <p v-if="plant.pollinators && plant.pollinators.length > 0">
              Pollinisé par les
              <span v-for="(pollinator, indexP) in plant.pollinators" :key="indexP">
              {{
                  indexP === plant.pollinators.length - 1 ? pollinator.toLowerCase() + '.' : pollinator.toLowerCase() + ', '
                }}
            </span>
            </p>
            <p v-if="plant.host_for && plant.host_for.length > 0">
              Hôte pour les
              <span v-for="(hosted, index) in plant.host_for" :key="index">
              {{ index === plant.host_for.length - 1 ? hosted.toLowerCase() + '.' : hosted.toLowerCase() + ', ' }}
            </span>
            </p>
          </div>
        </TextBlock>

        <TextBlock
            v-if="this.plant.rf_infos && (this.plant.rf_infos.mushrooms.length > 0
          || this.plant.rf_infos.bacteries.length > 0 || this.plant.rf_infos.bioagressors.length > 0
          || this.plant.rf_infos.viruses.length > 0)"
            collapsable
            title="Dangers">
          <feather-icon
              slot="titleSpace"
              class="text-danger"
              icon="AlertTriangleIcon"
              size="24"/>
          <app-collapse>
            <strong
                v-if="plant.rf_infos && plant.rf_infos.bacteries.length>0"
                class="text-danger">
              Bacteries
            </strong>
            <app-collapse-item
                v-for="danger in plant.rf_infos.bacteries"
                v-if="plant.rf_infos && plant.rf_infos.bacteries.length>0"
                :key="danger.vernacular_name"
                :title="danger.vernacular_name">
              <div class="pt-50 pl-50">{{ danger.description }}</div>
            </app-collapse-item>
            <strong
                v-if="plant.rf_infos && plant.rf_infos.bioagressors.length>0"
                class="text-danger">
              Bioagresseurs
            </strong>
            <app-collapse-item
                v-for="danger in plant.rf_infos.bioagressors"
                v-if="plant.rf_infos && plant.rf_infos.bioagressors.length>0"
                :key="danger.vernacular_name"
                :title="danger.vernacular_name">
              <div class="pt-50 pl-50">{{ danger.description }}</div>
            </app-collapse-item>
            <strong
                v-if="plant.rf_infos && plant.rf_infos.mushrooms.length>0"
                class="text-danger">
              Champignons
            </strong>
            <app-collapse-item
                v-for="danger in plant.rf_infos.mushrooms"
                v-if="plant.rf_infos && plant.rf_infos.mushrooms.length>0"
                :key="danger.vernacular_name"
                :title="danger.vernacular_name">
              <div class="pt-50 pl-50">{{ danger.description }}</div>
            </app-collapse-item>
            <strong
                v-if="plant.rf_infos && plant.rf_infos.viruses.length>0"
                class="text-danger">
              Virus
            </strong>
            <app-collapse-item
                v-for="danger in plant.rf_infos.viruses"
                v-if="plant.rf_infos && plant.rf_infos.viruses.length>0"
                :key="danger.vernacular_name"
                :title="danger.vernacular_name">
              <div class="pt-50 pl-50">{{ danger.description }}</div>
            </app-collapse-item>
          </app-collapse>
        </TextBlock>

        <TextBlock
            v-if="this.plant.parent_taxon
                  || this.plant.children_taxons.length > 0
                  || this.plant.siblings_taxons.length > 0"
            collapsable
            title="Famille">
          <img slot="titleSpace" alt="Icone croissance des plantes" class="icon"
              src="@/assets/images/icons/other/family.svg">

          <b-list-group flush>
            <b-list-group-item
                v-if="plant.parent_taxon"
                class="d-flex justify-content-between">
              <div>
                <strong> {{ plant.parent_taxon.common_name }} </strong><br>
                {{ plant.parent_taxon.scientific_name }}
              </div>
              <b-button
                  class="p-0"
                  pill
                  variant="flat-dark"
                  @click="newPlantInfo(plant.parent_taxon.id)">
                <feather-icon
                    slot="titleSpace"
                    icon="ArrowRightCircleIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>

            <b-list-group-item class="ml-2 text-primary">
              <strong> {{ plant.common_name }} </strong><br>
              {{ plant.scientific_name }}
            </b-list-group-item>

            <b-list-group-item
                v-for="child in limitList(plant.children_taxons)"
                v-if="plant.children_taxons.length > 0"
                :key="child.id"
                class="ml-4 d-flex justify-content-between">
              <div>
                <strong> {{ child.common_name }} </strong><br>
                {{ child.scientific_name }}
              </div>
              <b-button
                  class="p-0"
                  pill
                  variant="flat-dark"
                  @click="newPlantInfo(child.id)">
                <feather-icon
                    slot="titleSpace"
                    icon="ArrowRightCircleIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>

            <b-list-group-item v-if="!showAll && plant.children_taxons.length > 2" class="ml-4 text-primary">
              <b-button
                  class="p-0"
                  pill
                  variant="flat-secondary"
                  @click="showAllFamily">
                <feather-icon
                    slot="titleSpace"
                    icon="MoreHorizontalIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>

            <b-list-group-item
                v-for="sibling in limitList(plant.siblings_taxons)"
                v-if="plant.siblings_taxons.length > 0 && plant.scientific_name !== sibling.scientific_name"
                :key="sibling.id"
                class="ml-2 d-flex justify-content-between">
              <div>
                <strong> {{ sibling.common_name }} </strong><br>
                {{ sibling.scientific_name }}
              </div>
              <b-button
                  class="p-0"
                  pill
                  variant="flat-dark"
                  @click="newPlantInfo(sibling.id)">
                <feather-icon
                    slot="titleSpace"
                    icon="ArrowRightCircleIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>

            <b-list-group-item v-if="!showAll && plant.siblings_taxons.length > 2" class="ml-2 text-primary">
              <b-button
                  class="p-0"
                  pill
                  variant="flat-secondary"
                  @click="showAllFamily">
                <feather-icon
                    slot="titleSpace"
                    icon="MoreHorizontalIcon"
                    size="24"/>
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </TextBlock>
      </div>
    </b-card-body>

    <b-modal
        ref="addInvasivePlantPrompt"
        cancel-title="Annuler"
        cancel-variant="outline-danger"
        centered
        modal-class="modal-danger"
        ok-title="Ajouter"
        ok-variant="primary"
        title="Plante envahissante"
        @ok="addPlant(plant.id)">
      <b-alert
          class="py-1 m-1 w-100"
          show
          variant="danger">
        <b-row class="text-center align-items-center px-1">
          <b-col cols="2">
            <img alt="Icon negatif" src="@/assets/images/icons/other/invasive.svg" style="height: 50px;">
          </b-col>
          <b-col>
            Attention cette plante est considérée comme envahissante.
          </b-col>
        </b-row>
      </b-alert>
    </b-modal>

    <b-modal
        ref="goToInteractionsPrompt"
        cancel-title="Annuler"
        cancel-variant="outline-danger"
        centered
        modal-class="modal-danger"
        ok-title="Continuer"
        ok-variant="primary"
        title="Charger les cultures associées ?"
        @ok="exploreInteractions">
      Attention charger les cultures associées vous fera quitter votre recherche actuelle.
      <br>
      Etes-vous sur de continuer ?
    </b-modal>

    <b-modal
        ref="popUpEditPhoto"
        centered
        hide-footer
        title="Plant Photo">
      <EditPlantPhoto :plantPhoto="popUpPlantPhoto" @updated="updatePlantPhoto"/>
    </b-modal>
  </b-card>
</template>

<script type="text/javascript">
import {BAlert, BButton, BCard, BCardBody, BCol, BListGroup, BListGroupItem, BModal, BRow} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import JayaTimeline from '@/components/global/JayaTimeline.vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import EditPlantPhoto from '@/components/plants/EditPlantPhoto.vue'
import {mapState} from "vuex";

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BAlert,
    BListGroup,
    BListGroupItem,
    BModal,
    AppCollapse,
    AppCollapseItem,
    TextBlock,
    JayaTimeline,
    EditPlantPhoto,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      popUpBigPictures: false,
      popUpPlantPhoto: {
        id: null,
        author: '',
        source: '',
        license: '',
        order: 0,
        manual_status: null,
        photo: null,
      },
      gotPlant: false,
      plantPageId: -1,
      showAll: false,
      colors: {
        White: "Blanc",
        Green: "Vert",
        Red: "Rouge",
        Purple: "Violet",
        Brown: "Marron",
        Blue: "Bleu",
        Yellow: "Jaune",
        Orange: "Orange"
      },
      plantImageSwiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  props: {
    title: {
      type: String,
      default: "Default Title"
    },
    thumbnail: {
      type: String,
      default: "feuille"
    },
    plantId: {
      type: Number,
      default: -1
    },
    isInGarden: {
      type: Boolean,
      default: false
    },
    gardenPlantId: {
      type: Number,
      default: -1
    },
    plantPage: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.plantPageId = this.plantId
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
    }),

    plantPhotos() {
      this.gotPlant // Force update plantPhoto when getPlant change
      if (this.plant.photos.length > 0) {
        return this.plant.photos
            .map(photo => process.env.VUE_APP_BACKEND_URL + '/..' + photo.photo_url + "&size=600")
      }
      if (this.plant.pictos.length > 0) {
        return [require("@/assets/images/icons/plants/" + this.plant.pictos[0]["name"])]
      }
      return [require("@/assets/images/default_pictures/plant_default.svg")]
    },

    isMaintenance() {
      return (this.plant.rf_infos && this.plant.rf_infos.watering[0])
          || this.isTrims
          || this.isHarvests
    },
    isTrims() {
      if (this.gotPlant && this.plant.rf_infos && this.plant.rf_infos.trims.length > 0) {
        //make sure not all trims have incorrect dates
        let counter = 0
        for (const trim of this.plant.rf_infos.trims) {
          if (!this.isMonthsCorrect(trim.periods)) {
            counter++
          }
        }
        return counter !== this.plant.rf_infos.trims.length
      }
      return false
    },
    isHarvests() {
      if (this.plant.rf_infos && this.plant.rf_infos.organs.length > 0) {
        //make sure not all organs have incorrect dates
        let counter = 0
        for (const organ of this.plant.rf_infos.organs) {
          if (!this.isMonthsCorrect(organ.harvest_periods)) {
            counter++
          }
        }
        return counter !== this.plant.rf_infos.organs.length
      } else if (this.plant.usda_infos && this.plant.usda_infos.fruit_seed_period && this.plant.usda_infos.fruit_seed_period.length > 0 && this.plant.usda_infos.fruit_seed_period.length < 12) {
        return true
      }
      return false
    },

    isOptimalConditions() {
      return this.temperature
          || this.shade
          || this.fertility
          || (this.plant.rf_infos && this.plant.rf_infos.humidities.length > 0)
          || this.soilTexture.length > 0
    },
    temperature() {
      if (this.plant.rf_infos && this.plant.rf_infos.hardiness_zone) {
        return this.plant.rf_infos.hardiness_zone.start_temperature + '°C à ' + this.plant.rf_infos.hardiness_zone.end_temperature + '°C'
      } else if (this.plant.usda_infos && this.plant.usda_infos.temperature_minimum) {
        return Math.round(this.plant.usda_infos.temperature_minimum) + '°C'
      } else {
        return false
      }
    },
    shade() {
      if (this.plant.rf_infos && this.plant.rf_infos.expositions.length > 0) {
        if (this.plant.rf_infos.expositions.includes("Ombre")) {
          return 'Ombre'
        } else if (this.plant.rf_infos.expositions.includes("Mi-ombre")) {
          return 'Mi-ombre'
        } else {
          return 'Soleil'
        }
      } else if (this.plant.usda_infos && this.plant.usda_infos.shade_tolerance === "Intolerant") {
        return 'Soleil'
      } else if (this.plant.usda_infos && this.plant.usda_infos.shade_tolerance === "Intermediate") {
        return 'Mi-ombre'
      } else if (this.plant.usda_infos && this.plant.usda_infos.shade_tolerance === "Tolerant") {
        return 'Ombre'
      } else {
        return false
      }
    },
    fertility() {
      if (this.plant.usda_info && this.plant.usda_infos.fertility_requirement === "Low") {
        return 'Faible'
      } else if (this.plant.usda_info && this.plant.usda_infos.fertility_requirement === "Medium") {
        return 'Moyen'
      } else if (this.plant.usda_info && this.plant.usda_infos.fertility_requirement === "High") {
        return 'Fort'
      } else {
        return false
      }
    },
    soilTexture() {
      let results = []
      if (this.plant.usda_infos) {
        if (this.plant.usda_infos.adapted_to_fine_textured_soils) {
          results.push('fine')
        }
        if (this.plant.usda_infos.adapted_to_medium_textured_soils) {
          results.push('medium')
        }
        if (this.plant.usda_infos.adapted_to_coarse_textured_soils) {
          results.push('coarse')
        }
      }
      return results
    },

    isCharacteristics() {
      return this.category
          || this.duration
          || this.plant.usda_infos
          || (this.plant.usda_infos && this.plant.usda_infos.category)
          || this.height
          || this.rootDepth
          || this.flowerColor
          || (this.plant.usda_infos && this.plant.usda_infos.active_growth_period.length > 0)
          || (this.plant.rf_infos && this.plant.rf_infos.flowering_periods.length > 0)
    },
    duration() {
      if (!this.plant.usda_info || this.plant.usda_infos.duration === '') {
        return false
      }
      var durations = this.plant.usda_infos.duration.split(', ')
      var result = ''
      for (var i = 0; i < durations.length; i++) {
        if (durations[i] === "Biennial") {
          if (result !== '') {
            result += ', '
          }
          result += 'Bisannuelle'
        } else if (durations[i] === "Annual") {
          if (result !== '') {
            result += ', '
          }
          result += 'Annuelle'
        } else if (durations[i] === "Perennial") {
          if (result !== '') {
            result += ', '
          }
          result += 'Vivace'
        }
      }
      return result
    },
    category() {
      if (this.plant.rf_infos && this.plant.rf_infos.commercial_category !== '') {
        return this.plant.rf_infos.commercial_category
      } else if (this.plant.usda_info) {
        if (this.plant.usda_infos.growth_habit === '') {
          return false
        } else if (this.plant.usda_infos.growth_habit === "Forb/herb") {
          return 'Herbacée'
        } else if (this.plant.usda_infos.growth_habit === "Graminoid") {
          return 'Graminée'
        } else if (this.plant.usda_infos.growth_habit === "Lichenous") {
          return 'Lichen'
        } else if (this.plant.usda_infos.growth_habit === "Shrub") {
          return 'Arbuste'
        } else if (this.plant.usda_infos.growth_habit === "Subshrub") {
          return 'Arbuste'
        } else if (this.plant.usda_infos.growth_habit === "Tree") {
          return 'Arbre'
        } else if (this.plant.usda_infos.growth_habit === "Vine") {
          return 'Grimpante'
        }
      } else {
        return false
      }
    },
    height() {
      if (this.plant.usda_infos && Math.round(this.plant.usda_infos.height_mature) !== 0) {
        return this.truncateSize(this.plant.usda_infos.height_mature) + 'm'
      }
      return false
    },
    rootDepth() {
      if (this.plant.usda_infos && Math.round(this.plant.usda_infos.root_depth_minimum) !== 0) {
        return this.truncateSize(this.plant.usda_infos.root_depth_minimum) + 'm'
      }
      return false
    },
    flowerColor() {
      if (this.plant.usda_infos && this.plant.usda_infos.flower_color !== '') {
        return this.colors[this.plant.usda_infos.flower_color]
      }
      return false
    },

    plantInfoCount() {
      let isFamily = (this.plant.parent_taxon || this.plant.children_taxons.length > 0 || this.plant.siblings_taxons.length > 0)
      return (isFamily ? 1 : 0) + (this.isCharacteristics ? 1 : 0) + (this.isOptimalConditions ? 1 : 0) + (this.isMaintenance ? 1 : 0)
    }
  },
  methods: {
    copyLink() {
      this.$copyLink("plants", this.plant.id)
    },
    checkInvasiveStatus(plantId) {
      if (this.plant.invasive) {
        this.$refs.addInvasivePlantPrompt.show()
      } else {
        this.addPlant(plantId)
      }
    },
    addPlant(plant) {
      const obj = {
        plant: plant, garden: this.$store.state.garden.gardens.find(gd => {
          return gd.is_active
        }).id
      }
      this.$store.dispatch("garden/addPlant", obj)
          .then(() => {
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Plante ajoutée",
              text: this.plant.common_name + " ajoutée à votre jardin"
            })
            this.$store.dispatch("feedback/getScore", {
              garden: this.$store.state.garden.gardens.find(garden => {
                return garden.is_active
              })["id"],
              plant: plant, check_score: "better"
            })
                .then(response => {
                  if (response.data[0].better_biodiv_score) {
                    this.$notify({
                      time: 5000,
                      color: "primary",
                      title: "Score de biodiversité amélioré",
                      text: "Rendez vous l'onglet biodiversité pour découvrir comment améliorer encore votre score"
                    })
                  }
                })
          })
    },
    deletePlant(id, plant_id) {
      const obj = {
        id: id, garden: this.$store.state.garden.gardens.find(garden => {
          return garden.is_active
        }).id
      }
      this.$store.dispatch("garden/removePlant", obj)
          .then(() => {
            this.$notify({
              time: 4000,
              color: "warning",
              title: "Plante supprimée",
              text: this.plant.common_name + " retirée de votre jardin"
            })
            this.$store.dispatch("feedback/getScore", {
              garden: this.$store.state.garden.gardens.find(garden => {
                return garden.is_active
              })["id"],
              plant: plant_id, check_score: "worse"
            })
                .then(response => {
                  if (response.data[0].worse_biodiv_score) {
                    this.$notify({
                      time: 5000,
                      color: "warning",
                      title: "Votre score de biodiversité a baissé",
                      text: "Le nombre de strates végétales de votre jardin a baissé suite au retrait de " + this.plant.common_name + ". Rendez vous sur l'onglet biodiversité pour apprendre comment le faire augmenter."
                    })
                    this.$store.commit("feedback/SET_UPDATE_BIODIV_SCORE", 'update_score')
                  }
                })
          })
          .catch(error => {
            if (error.response && error.response.status == 403) {
              if (error.response.data.detail == "Vegetable Garden Plant") {
                this.$notify({
                  time: 6000,
                  color: "danger",
                  title: "Plante potagère",
                  text: "Cette plante est dans un potager veuillez vous rendre sur sa carte de placement pour l'y supprimer"
                })
              }
            }
          })
    },
    AllergySensibilityCount(int) {
      let result;
      result = int * 10 / 2
      return result
    },
    clickx(e) {
      e.stopPropagation()
    },
    newPlantInfo(newId) {
      this.plantPageId = newId
      this.showAll = false
    },
    limitList(array) {
      if (array.length > 2 && !this.showAll) {
        return array.slice(0, 2)
      }
      return array
    },
    truncateSize(height) {
      if (height < 1) {
        return height.toFixed(2)
      }
      if (height < 10) {
        return height.toFixed(1)
      }
      return height.toFixed(0)
    },
    showAllFamily() {
      this.showAll = true
    },
    isMonthsCorrect(monthsArray) {
      return this.$options.filters.numericMonthsArray(monthsArray).length > 0
    },
    showImageViewer() {
      let index = this.$refs.swiper.swiper.activeIndex
      this.$refs.viewer[index].$el.viewer.show()
    },
    getAlt(author, licence) {
      if (author && licence) {
        return 'Photo de ' + author + ', ' + licence
      } else if (author) {
        return 'Photo de ' + author
      }
      return licence ? licence : ''
    },
    editImage() {
      let index = 0
      if (this.$refs.swiper) {
        index = this.$refs.swiper.swiper.activeIndex
      }
      this.popUpPlantPhoto = this.plant.photos[index]
      // Add photo
      if (this.popUpPlantPhoto.photo_url) {
        this.popUpPlantPhoto.photo = {
          url: process.env.VUE_APP_BACKEND_URL + '/..' + this.popUpPlantPhoto.photo_url
        }
      }
      // Show edit popup
      this.$refs.popUpEditPhoto.show()
    },
    addImage() {
      let order = 0
      // Compute last order photo
      if (this.plant.photos.length > 0) {
        let index = this.plant.photos.length - 1
        order = this.plant.photos[index].order
      }
      // Create empty plant photo data
      this.popUpPlantPhoto = {
        plant: this.plant.id,
        author: '',
        source: '',
        license: '',
        order: order,
        manual_status: 'C',
        photo: null,
      }
      // Show edit popup
      this.$refs.popUpEditPhoto.show()
    },
    updatePlantPhoto() {
      // Close popup
      this.$refs.popUpEditPhoto.hide()
      // Force update photos
      this.plantPageId = -1
      setTimeout(() => this.plantPageId = this.plant.id, 100)
    },
    exploreInteractions() {
      let plantIdList = []
      if (!localStorage.interactions_module_id_list) {
        localStorage.setItem('interactions_module_id_list', JSON.stringify([]))
      } else {
        plantIdList = JSON.parse(localStorage.interactions_module_id_list)
      }
      if (!plantIdList.includes(this.plant.id)) {
        plantIdList.push(this.plant.id)
      }
      localStorage.setItem('interactions_module_id_list', JSON.stringify(plantIdList))
      this.$router.push({name: 'interactions-explorer'})
    }
  },
  watch: {
    plantPageId(id) {
      if (id >= 0) {
        this.plant = []
        this.gotPlant = false
        this.$store.dispatch("plant/get", {id: this.plantPageId})
            .then(response => {
              this.plant = response.data
              this.gotPlant = true
            })
            .catch((error) => {
              this.plant = []
              if (this.plantPage) {
                if (error.response.status == 404) {
                  this.$router.push({name: "error-404"})
                }
                if (error.response.status == 429) {
                  this.$notify({
                    time: 10000,
                    title: "Limite atteinte",
                    text: "Vous avez atteint la limite quotidienne de visualisation de plantes, pour continuer, veuillez créer un compte"
                  })
                  this.$router.push({name: "accueil"})
                }
              } else {
                this.$notify({
                  time: 4000,
                  title: "Erreur",
                  text: "Une erreur est survenue au chargement de cette plante.",
                  color: "danger"
                })
              }
            })
      }
    }
  }
}
</script>

<style lang="scss">
.swiper-box {
  z-index: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.plant-info {
  width: 100%;

  .card {
    background-color: transparent;
    box-shadow: none;

    .card-header {
      padding-bottom: 0;
    }

    .card-body {
      padding-top: 0;
    }
  }

  .list-group .list-group-item {
    background-color: transparent;
  }


  .icon {
    height: 30px;
  }

  .icon-small {
    height: 20px;
    width: 30px;
    padding-right: 5px;
  }

  .truncate-slot {
    .vs-list--slot {
      text-align: right;
      padding-left: 5px;
    }

    .list-titles {
      width: 100%
    }
  }

  h4 {
    color: rgba(var(--vs-dark), 1) !important;
    font-weight: 500;
    padding-top: 5px;
  }
}
</style>
