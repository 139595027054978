<template>
  <div class="notification-card" @click="performMoreInfos">
    <div class="overflow-hidden border-bottom-light" @click="performMoreInfos">
      <b-row no-gutters>
        <b-col class="notif-col" cols="3">
          <div class="d-flex h-100">
            <img :src="getImage" alt="Image" class="responsive" style="max-width: 100%;"/>
          </div>
        </b-col>
        <b-col class="words-break pl-50" cols="9">
          <b-card-body>
            <span v-html="action"/>
          </b-card-body>
          <slot></slot>
        </b-col>
      </b-row>
    </div>
    <JayaPopUp id="Popupquestion" ref="popupRef" title="Notification">
      <b-card v-if="type==='PO'" class="max-w-lg">
        <QuestionInfo
            :closeDD="closeDD"
            :idAnswerCenterer="concernedId"
            :post="objectMoreInfos"
            style="width:90%"
            @postDeleted="stopPopUp"
        />
      </b-card>
      <PlantInfo
          v-else-if="type==='TR' || type==='OR'"
          :gardenPlantId="concernedId"
          :plantId="objectMoreInfos"
          isInGarden
          @close-delete="stopPopUp"/>
      <Notes
          v-else-if="type==='RE' && this.note"
          :show-date="new Date(this.note.date)"
          :associated-garden="this.note.associatedGarden"/>
      <div v-else>
        <b-card>
          {{ action }}
        </b-card>
      </div>
    </JayaPopUp>
  </div>
</template>

<script type="text/javascript">
import { BCard, BCardBody, BCol, BRow } from 'bootstrap-vue'
import QuestionInfo from '@/components/forum/QuestionInfo.vue'
import PlantInfo from "@/components/plants/PlantInfo.vue"
import JayaPopUp from "@/components/global/JayaPopUp";
import Notes from "@/components/notes/Notes.vue";

export default {
  name: "notification-card",
  components: {
    QuestionInfo,
    PlantInfo,
    BCard,
    BCardBody,
    BCol,
    BRow,
    JayaPopUp,
    Notes,
  },
  data() {
    return {
      objectMoreInfos: null,
      popUpMoreInfo: false,
      closeDD: false,
      note: null,
      typeParser: {
        'DF' : 'notification',
        'TR' : 'plant',
        'OR' : 'plant',
        'PO' : 'question',
        'RE' : 'note',
      }
    }
  },
  props: {
    action: {
      type: String,
      default: "Titre de la notification"
    },
    type: { //options are 'DF' : notification(default), 'TR'/'OR' : plant, 'PO' : question, 'RE' : note,  'event'
      type: String,
      default: "DF"
    },
    typeObjectId: {
      type: Number,
      default: -1
    },
    concernedId: {
      type: Number,
      default: -1
    },
    notifId: {
      type: Number,
      default: -1
    },
    extraInfos: {
      type: String,
      default: ""
    }
  },
  computed: {
    getImage() {
      let img = ""
      try {
        img = require("@/assets/images/default_pictures/" + this.typeParser[this.type] + "_default.svg")
      } catch (e) {
        return require("@/assets/images/default_pictures/notification_default.svg")
      }
      return img
    },
    imageDescription() {
      return "Image de " + this.title
    }
  },
  methods: {
    performMoreInfos(event) {
      event.capture = true
      event.stopPropagation()
      if (this.type === 'PO') {
        this.$store.dispatch("forum/getPost", this.typeObjectId)
            .then(response => {
              this.objectMoreInfos = response.data
              this.$refs.popupRef.openPopUp()
            })
            .catch(error => {
              if (error.response && error.response.status === 404) {
                this.$emit("delete:notif", this.notifId)
              }
            })
      } if (this.type === 'RE') {
        this.$store.dispatch("notes/getNote", this.typeObjectId)
            .then((result) => {
              this.note = result
              this.note.kind = "Note"
              if (this.note.garden) {
                this.note.associatedGarden = this.$store.state.garden.gardens.find(garden => garden.id === this.note.garden)
                this.$refs.popupRef.openPopUp()
              } else if (this.note.vegetable_garden){
                this.$store.dispatch("garden/getVGarden", this.note.vegetable_garden)
                .then((vgardenResult) => {
                  this.note.associatedGarden = this.$store.state.garden.gardens.find(garden => garden.id === vgardenResult.data.garden)
                  this.$refs.popupRef.openPopUp()
                })
              }
            })
        .catch(() => {
          this.$notify({
            title: "Une erreur est survenu lors de la recherche de cette note",
            color: "danger",
            time: 3000
          })
        })
      } else {
        this.objectMoreInfos = this.typeObjectId
        this.$refs.popupRef.openPopUp()
      }
    },
    stopPopUp() {
      this.$refs.popupRef.removeHash()
      this.$refs.popupRef.closePopUp()
    }
  },
  watch: {
    '$route.hash'() {
      this.popUpMoreInfo = false
    },
    closeDD(value) {
      if (value) {
        setTimeout(() => {
          this.closeDD = false
        }, 10)
      }
    }
  }
}
</script>

<style lang="scss" media="screen">
.notification-card {
  position: relative;
  width: 100%;
  margin: auto;
  height: 6rem;
  .responsive {
    object-fit: cover;
  }

  .extra-infos-logo {
    margin-top: 2px;
  }

  .card-body {
    height: 6rem;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .words-break {
    white-space: break-spaces;
  }
}
</style>
