<template>
  <div class="vgarden-plant-feedback overflow-auto w-100">
    <div class="d-flex flex-wrap px-50">
      Récolte en :
      <select v-model="feedBack.type" class="ml-2">
        <option value="mass"> Masse (Kilograms)</option>
        <option value="quantity"> Quantité (fruits, tubercules, feuilles...)</option>
      </select>

      <div v-if="feedBackTotal > 0" class="ml-50 font-weight-bold">
        Total : {{ feedBackTotal }}
      </div>

      <p class="text-italic p-50">
        *Cliquez sur une plante pour indiquer une récolte
      </p>
    </div>

    <div :id="'vgarden-plant-feedback' + plantId.toString()" class="d-flex justify-content-center w-100 mt-2">
      <VGardenCanvas
          :vGarden="vGardenForFeedBack"
          :xLength="vGardenXLength"
          :yLength="vGardenYLength"
          feedback
          v-on:feedback="newFeedback"/>
    </div>
  </div>
</template>

<script type="text/javascript">
import VGardenCanvas from '@/components/vgarden/VGardenCanvas'

export default {
  name: "vgarden-plant-feedback",
  components: {
    VGardenCanvas
  },
  props: {
    vGarden: {
      type: Object,
      required: true
    },
    plantId: {
      type: Number,
      required: true
    },
    onlyVGPlantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      activePlant: null,
      feedBack: {
        plantResults: [],
        type: 'mass'
      },
      vGardenXLength: 500
    }
  },
  mounted() {
    this.updateVGLength()
  },
  computed: {
    vGardenForFeedBack() {
      let modfiedVGarden = JSON.parse(JSON.stringify(this.vGarden))
      if (this.onlyVGPlantId) {
        modfiedVGarden.vgarden_plants = [this.vGarden.vgarden_plants.find(plant => plant.id === this.onlyVGPlantId)]
      } else {
        if (modfiedVGarden.vgarden_plants.length > 0) {
          modfiedVGarden.vgarden_plants = []
          for (const plant of this.vGarden.vgarden_plants) {
            var modfiedPlant = JSON.parse(JSON.stringify(plant))
            if (modfiedPlant.garden_plant.plant.id === this.plantId && modfiedPlant.current_state === 'PL') {
              modfiedVGarden.vgarden_plants.push(modfiedPlant)
            }
          }
        }
      }
      return modfiedVGarden
    },
    vGardenYLength() {
      return this.vGardenXLength * this.vGarden.width / this.vGarden.length
    },
    feedBackTotal() {
      let total = 0
      if (this.feedBack && this.feedBack.plantResults.quantity) {
        for (const ans of this.feedBack.plantResults.quantity.filter(item => !isNaN(item))) {
          total += Number(ans)
        }
      }
      return Number(total)
    }
  },
  methods: {
    newActivePlant(event) {
      this.activePlant = event
    },
    newFeedback(val) {
      this.feedBack.plantResults = val
      this.$emit('newFeedback', this.feedBack)
    },
    updateVGLength() {
      setTimeout(() => {
        let vgardenDivId = "vgarden-plant-feedback" + this.plantId.toString()
        let divWidth = document.getElementById(vgardenDivId) ? document.getElementById(vgardenDivId).offsetWidth : 500
        if (divWidth < 900) {
          this.vGardenXLength = divWidth
        } else {
          this.vGardenXLength = 900 - 61.6
        }
      }, 300)
    }
  },
  watch: {
    'feedBack.type'() {
      this.$emit('newFeedback', this.feedBack)
    },
    '$store.state.app.windowWidth'() {
      this.updateVGLength()
    },
  }
}
</script>
