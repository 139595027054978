<template>
  <div>
    <div class="w-100 d-flex justify-content-between align-items-center px-2">
      <span class="text-primary pt-1" style="font-size: 45px !important; font-family: Hortypo;">
        Hortilio
      </span>

      <b-link>
        <b-button @click="$emit('closeMenu')"
                  variant="flat-primary"
                  class="btn-icon">
          <feather-icon
              class="d-block text-primary"
              icon="XIcon"
              size="20"/>
        </b-button>
      </b-link>
    </div>

    <!-- Hortilio local Authority administration-->
    <div v-if="isAdministrator">
      <jaya-divider :thickness="1" color="info" text="ADMINISTRER"/>

      <div
          :class="localAuthorityActive ? 'jaya-menu-button-active-LA' : ''"
          class="jaya-menu-button cursor-pointer d-flex align-items-center justify-content-start mx-1"
          @click="administrate()">
        <feather-icon
            class="mr-1"
            icon="SlidersIcon"
            size="20"/>
        <span class="">{{ isAdministrator.name }}</span>
      </div>

      <jaya-divider :thickness="1" color="info"/>
    </div>

    <!-- For Coupe de france du potager -->
    <div v-if="$store.state.account.user && $store.state.account.user.user_creation_origin && $store.state.account.user.user_creation_origin.includes('cdf_potager')">
      <jaya-divider :thickness="1" class="pt-50" color="info" v-if="!isAdministrator"/>
      <div
          :class="'/coupedefrancedupotager' === $route.path ? 'jaya-menu-button-active' : ''"
          class="jaya-menu-button cursor-pointer d-flex align-items-center justify-content-start mx-1"
          @click="$router.push({name: 'coupe-de-france-du-potager'})">
        <feather-icon
            class="mr-1"
            icon="ListIcon"
            size="20"/>
        <span class="align-middle"> Coupe de France <br> du Potager </span>
      </div>
      <jaya-divider :thickness="1" class="pb-50" color="info"/>
    </div>

    <!-- Hortilio main tabs-->
    <div
        v-for="(tab, index) in mainTabs"
        :key="index"
        :class="tab.active && $route.path === '/' ? 'jaya-menu-button-active' : ''"
        class="jaya-menu-button cursor-pointer d-flex align-items-center justify-content-start mx-1"
        @click="goToTab(tab.hash)">
      <feather-icon
          :icon="tab.icon + 'Icon'"
          class="mr-1"
          size="20"/>
      <span class="">{{ tab.name }}</span>
    </div>

    <!-- Hortilio Tools Pages-->
    <jaya-divider :thickness="1" class="pt-50" color="primary" text="OUTILS"/>

    <div
        v-for="(page, index) in hortilioToolPages"
        :key="index + mainTabs.length"
        :class="page.active ? 'jaya-menu-button-active' : ''"
        class="jaya-menu-button cursor-pointer d-flex align-items-center justify-content-start mx-1"
        @click="goToPage(page.route)">
      <feather-icon
          :icon="page.icon + 'Icon'"
          class="mr-1"
          size="20"/>
      <span class="">{{ page.name }}</span>
    </div>

    <!-- Hortilio Other Pages-->
    <jaya-divider :thickness="1" class="pt-50" color="secondary" text="AUTRES"/>

    <div
        v-for="(page, index) in hortilioOtherPages"
        :key="index + mainTabs.length + hortilioToolPages.length"
        :class="page.active ? 'jaya-menu-button-active' : ''"
        class="jaya-menu-button cursor-pointer d-flex align-items-center justify-content-start mx-1"
        @click="goToPage(page.route)">
      <feather-icon
          :icon="page.icon + 'Icon'"
          class="mr-1"
          size="20"/>
      <span class="">{{ page.name }}</span>
    </div>


    <!-- Hortilio local Authority images -->
    <div>
      <div class="d-flex justify-content-around w-100 my-3">
        <div
            style="height: 100px;">
          <img src="http://www.tolefipromotions.com/wp-content/uploads/2018/02/fpi-logo.png" class="object-contain h-100">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalNavMenuItems
  from "@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuItems";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {BImg, BLink, BButton} from "bootstrap-vue"

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BButton,
  },
  data() {
    return {
      localAuthority: null,
      localAuthorityActive: false,

      mainTabs: [
        {name: 'Mon Jardin', icon: 'Home', hash: '#jardin', active: false},
        {name: 'Autour de moi', icon: 'Calendar', hash: '#autour-de-moi', active: false},
        {name: 'Coup de Pousse', icon: 'MessageCircle', hash: '#forum', active: false},
        {name: 'Encyclopédie', icon: 'BookOpen', hash: '#encyclopedie', active: false}
      ],
      hortilioToolPages: [
        {name: "Carnet de notes", route: "notes", icon: "PenTool", active: false},
        {name: "Fiches pratiques", route: "knowledge-base", icon: "FileText", active: false},
        {name: "Cultures associées", route: "interactions-explorer", icon: "RefreshCw", active: false},
        {name: "Identifier son sol", route: "soil-identification-page", icon: "Eye", active: false},
        {name: "Mon potager", route: "vgardens", icon: "Feather", active: false},
        {name: "Challenge résidence", route: "vgardens", icon: "Award", active: false}
      ],
      hortilioOtherPages: [
        {name: "Guide d'utilisation", route: "guide", icon: "BookOpen", active: false},
        {name: "A propos", route: "about-us", icon: "Info", active: false},
        {name: "Contactez nous", route: "contact-us", icon: "MessageSquare", active: false},
        {name: "Mentions Légales", route: "legal-notice", icon: "Archive", active: false}
      ]
    }
  },
  computed: {
    isAdministrator() {
      return this.$store.state.localAuthority.memberOf
    },
    getActiveLocalAuthorities() {
      let activeGarden = this.$store.state.garden.gardens.find(garden => {
        return garden.is_active
      })
      if (activeGarden.local_authority) {
        let gardenLocalAuthority = this.$store.state.localAuthority.subscriptions.find(localAuthority => {
          return localAuthority.id === activeGarden.local_authority
        })
        //make sure user is subscribed to active garden local authority
        if (gardenLocalAuthority) {
          if (gardenLocalAuthority.zip_codes.length > 1) {
            return [gardenLocalAuthority]
          } else {
            return this.$store.state.localAuthority.subscriptions.filter(localAuthority => {
              return localAuthority.zip_codes.includes(gardenLocalAuthority.zip_codes[0])
            })
          }
        }
      }
    }
  },
  methods: {
    goToTab(hash) {
      for (let i = 0; i < this.hortilioToolPages.length; i++) {
        this.hortilioToolPages[i].active = false
      }
      for (let i = 0; i < this.hortilioOtherPages.length; i++) {
        this.hortilioOtherPages[i].active = false
      }
      for (let i = 0; i < this.mainTabs.length; i++) {
        this.mainTabs[i].hash === hash ? this.mainTabs[i].active = true : this.mainTabs[i].active = false
      }
      if (this.$route.hash !== hash) {
        this.$router.push({name: 'jayaccueil', hash: hash})
      }
      this.localAuthorityActive = false
    },
    goToPage(route) {
      for (let i = 0; i < this.mainTabs.length; i++) {
        this.mainTabs[i].active = false
      }
      for (let i = 0; i < this.hortilioToolPages.length; i++) {
        this.hortilioToolPages[i].route === route ? this.hortilioToolPages[i].active = true : this.hortilioToolPages[i].active = false
      }
      for (let i = 0; i < this.hortilioOtherPages.length; i++) {
        this.hortilioOtherPages[i].route === route ? this.hortilioOtherPages[i].active = true : this.hortilioOtherPages[i].active = false
      }
      if (this.$route.name !== route)
        this.$router.push({name: route})

      this.localAuthorityActive = false
    },
    administrate() {
      this.$router.push('/local-authorities/admin')
      this.localAuthorityActive = true
    },
    getImage(localAuth) {
      return localAuth.photo_url
          ? process.env.VUE_APP_BACKEND_URL + '/..' + localAuth.photo_url + "&size=350"
          : require('@/assets/images/default_pictures/local_authority_default.jpg')
    },
    getImageDescription(localAuth) {
      return "Image de " + localAuth.name
    }
  }
}
</script>
