<template>
  <div class="d-flex content align-items-center justify-content-between w-100 h-100"
       style="padding: 0.6rem 1.5rem 0 1.5rem !important;max-width: 100vw;"
  >

    <!-- Nav Menu Toggler -->
    <b-button
        v-b-toggle.jaya-menu
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn-icon p-0 mb-1"
        style="z-index: 950;"
        variant="flat-primary"
    >
      <feather-icon icon="MenuIcon" size="24"/>
    </b-button>

    <!-- USER META -->
    <b-navbar-nav>
      <b-nav-item-dropdown
          class="dropdown-user"
          right
          style="z-index: 950;"
          toggle-class="d-flex align-items-center dropdown-user-link"
          @toggle="seeNotifications"
      >

        <template #button-content>
          <b-avatar :badge="notificationsCount > 0 ? notificationsCount.toString() : null" badge-top
                    badge-variant="danger" class="jayavatar" variant="primary"
          >
            <span v-if="user_displayName" class="text-primary jayavatar-text" style="color:var(--primary) !important;">
              {{ user_displayName }}
            </span>
            <feather-icon v-else
                          icon="UserIcon" style="color: var(--primary) !important;"
            />
          </b-avatar>
        </template>

        <div id="testID" class="notifications-zone">
          <b-dropdown-item-button
              v-if="notifications"
              v-for="(notification, key) in notifications"
              :key="key"
              class="notification-item"
          >
            <NotificationCard
                :action="notification.text"
                :concernedId="notification.object_id"
                :extraInfos="notification.more_infos.type"
                :notifId="notification.id"
                :type="notification.more_infos.type ? notification.more_infos.type : 'DF'"
                :typeObjectId="notification.more_infos.id_meta_concerned"
                @delete:notif="deleteNotif"
            />
          </b-dropdown-item-button>
        </div>

        <b-dropdown-item v-if="this.next">
          <p class="text-primary" @click="loadMoreNotif">Charger plus</p>
        </b-dropdown-item>

        <div class="dropdown-divider"/>
        <b-dropdown-item @click="popUpSettings">
          <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4"/>
          <span class="ml-2">Paramètres du compte</span>
        </b-dropdown-item>

        <b-dropdown-item-button @click="logout">
          <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
          <span class="ml-2">Se déconnecter</span>
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <AccountSettings ref="accountSettings"/>

    <b-sidebar
        id="jaya-menu"
        backdrop
        bg-variant="white"
        no-header
        shadow
        width="260px"
    >
      <template #default="{ hide }">
        <JayaMenu @closeMenu="hide"/>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BDropdownItem,
  BDropdownItemButton,
  BNavbarNav,
  BNavItemDropdown,
  BSidebar,
  VBToggle,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import JayaMenu from '@/components/menu/JayaMenu.vue'
import AccountSettings from '@/components/user/AccountSettings.vue'
import NotificationCard from '@/components/notifications/NotificationCard.vue'

export default {
  components: {
    BButton,
    BSidebar,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BAvatar,
    JayaMenu,
    AccountSettings,
    NotificationCard
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      notifications: null,
      next: null,
      clickedNotify: false,
      limit: 5,
      seenParam: false,
      offset: 0,
      notificationsCount: 0,
    }
  },
  mounted() {
    let el = document.getElementById('testID')
    document.addEventListener("newnotification", () => {
      this.$store.dispatch('notifications/fetchNotifications', {
        limit: this.limit,
        offset: 0,
        seen: false
      })
          .then(response => {
            this.notifications = response.data.results
            this.notificationsCount = response.data.count
            this.next = response.data.next
            this.offset = this.offset + this.limit
            if (response.data.count < this.limit) {
              this.seenParam = true
              let localLimit = this.limit - response.data.count
              this.$store.dispatch('notifications/fetchNotifications', {
                limit: localLimit,
                offset: 0,
                seen: this.seenParam
              })
                  .then(response => {
                    this.notifications = this.notifications.concat(response.data.results)
                    this.next = response.data.next
                  })
            }
          })
    })
    el.addEventListener('click', function (e) {
      if ((e.target).matches('li.notifications-zone')) {
        e.stopPropagation()
      }
      if ((e.target).matches('button.dropdown-item')) {
        e.stopPropagation()
      }
    }, { capture: true })
    this.$store.dispatch('notifications/fetchNotifications', {
      limit: this.limit,
      offset: this.offset,
      seen: this.seenParam
    })
        .then(response => {
          this.notifications = response.data.results
          this.notificationsCount = response.data.count
          this.next = response.data.next
          this.offset = this.offset + this.limit
          if (response.data.count < this.limit && !this.seenParam) {
            this.seenParam = true
            let localLimit = this.limit - response.data.count
            this.$store.dispatch('notifications/fetchNotifications', {
              limit: localLimit,
              offset: 0,
              seen: this.seenParam
            })
                .then(response => {
                  this.notifications = this.notifications.concat(response.data.results)
                  this.next = response.data.next
                  // this.offset = localLimit
                })
          }
        })
    if (this.$store.state.localAuthority.transparent_header) {
      setTimeout(() => {
        document.getElementById('status-bar').content = '#f5f5f5'
      }, 2)
    }
  },
  beforeDestroy() {
    let el = document.getElementById('testID')
    if (el) {
      el.removeEventListener('click', null, true)
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
    }),
    // navbarColorLocal() {
    //   return this.$store.state.theme === "dark" ? "#10163a" : this.navbarColor
    // },
    // },
    //
    // // PROFILE
    activeUserInfo() {
      return this.$store.state.account.user
    },
    user_displayName() {
      if (this.activeUserInfo && this.activeUserInfo.first_name) {
        return this.activeUserInfo.first_name.length <= 3 ? this.activeUserInfo.first_name : this.activeUserInfo.first_name[0]
      } else {
        return ''
      }
    },
    checkTransparency() {
      return this.$store.state.localAuthority.transparent_header
    }
  },
  methods: {
    popUpSettings() {
      this.$refs.accountSettings.popUpSettings()
    },
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    },
    logout() {
      this.$loading(true)
      this.$logout()
          .then(() => {
            this.$loading(false)
            this.$notify({
              time: 4000,
              color: 'primary',
              title: 'Déconnecté',
              text: 'Vous avez bien été déconnecté, à bientôt !'
            })
            this.$router.push({ name: 'login' })
          })
          .catch(() => {
            this.$loading(false)
            this.$notify({
              time: 4000,
              color: 'danger',
              title: 'Erreur technique',
              text: 'Une erreur est survenue à la déconnexion, veillez rééssayer plus tard'
            })
          })
    },
    login() {
      this.$router.push({ name: 'login' })
    },

    // Method for creating dummy notification time
    randomDate({
      hr,
      min,
      sec
    }) {
      let date = new Date()

      if (hr) date.setHours(date.getHours() - hr)
      if (min) date.setMinutes(date.getMinutes() - min)
      if (sec) date.setSeconds(date.getSeconds() - sec)

      return date
    },
    loadMoreNotif(e) {
      e.capture = true
      e.stopPropagation()
      let payload = {
        limit: this.limit,
        seen: this.seenParam
      }
      if (this.seenParam) {
        payload.offset = this.offset
      }
      this.$store.dispatch('notifications/fetchNotifications', payload)
          .then(response => {
            this.notifications = this.notifications.concat(response.data.results.filter((item) => this.notifications.indexOf(item) < 0))
            this.next = response.data.next
            this.offset = this.offset + this.limit
            let unseenNotifications = response.data.results.filter((item) => {
              return item.seen == false
            })
            for (let notification of unseenNotifications) {
              this.$store.dispatch('notifications/putNotification', notification.id)
                  .then(() => {
                    notification.seen = true
                  })
                  .catch(() => {
                    this.$notify({
                      title: 'Erreur',
                      text: 'Impossible de marquer cette notification comme lue',
                      color: 'danger',
                      time: 4000
                    })
                    this.notificationsCount += 1
                  })
              if (response.data.count > 5) {
                this.notificationsCount -= 5
              } else {
                this.notificationsCount -= response.data.count
              }
            }
            if (response.data.count < this.limit && !this.seenParam) {
              this.seenParam = true
              let localLimit = this.limit - response.data.count
              this.$store.dispatch('notifications/fetchNotifications', {
                limit: localLimit,
                offset: this.offset - localLimit,
                seen: this.seenParam
              })
                  .then(response => {
                    // Only add the notifications that are not in the list.
                    this.notifications = this.notifications.concat(response.data.results.filter((item) => this.notifications.indexOf(item) < 0))
                    this.next = response.data.next
                  })
            }
          })
    },
    seeNotifications() {
      if (!this.clickedNotify) {
        let unseenNotifications = this.notifications.filter((item) => {
          return item.seen == false
        })
        for (let notification of unseenNotifications) {
          this.$store.dispatch('notifications/putNotification', notification.id)
              .then(() => {
                notification.seen = true
              })
              .catch(() => {
                this.$notify({
                  title: 'Erreur',
                  text: 'Impossible de marquer cette notification comme lue',
                  color: 'danger',
                  time: 4000
                })
                this.notificationsCount += 1
              })
        }
        this.notificationsCount -= 5
        this.clickedNotify = true
      }
    },
    deleteNotif(event) {
      this.notifications = this.notifications.filter((item) => {
        return item.id != event
      })
    }
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'click-outside': {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
      },

      unbind: function (el) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null

      }
    }
  },
}
</script>

<style lang="scss">
.jayavatar {
  //box-shadow: 0 8px 15px -8px var(--dark) !important;
  background-color: white !important;
  width: 50px;
  height: 50px;
  border: solid 1px var(--primary-light) !important;

  .b-avatar-badge {
    font-size: 100% !important;
  }
}

.jayavatar-text {
  font-family: Hortypo;
  font-size: 2rem;
}

.notifications-zone {
  max-height: 50vh;
  overflow: auto;
  background-color: white !important;
}

.dropdown-menu {
  width: 22rem !important;
  padding-top: 0;
  background-color: white !important;
}

.notification-item {
  width: 100%;
  background-color: white !important;

  .dropdown-item {
    width: 100%;
    padding: 0;
  }
}
</style>
