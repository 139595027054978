<template>
  <b-modal
      v-if="user"
      v-model="settingsIsPopUp"
      centered
      hide-footer
      title="Paramètres du compte">
    <div class="account-settings">
      <b-list-group flush>
        <b-list-group-item
            class="d-flex justify-content-between">
          <div>
            <span class="font-weight-bold">
              Email
            </span>
            <br>
            <span class="font-small-3">
              {{ user.email }}
            </span>
          </div>
        </b-list-group-item>
        <b-list-group-item
            class="d-flex justify-content-between">
          <div>
            <span class="font-weight-bold">
              Prénom
            </span>
            <br>
            <span class="font-small-3">
              {{ user.first_name }}
            </span>
          </div>
          <b-button
              class="ml-1 p-50"
              pill
              size="sm"
              variant="flat-primary"
              @click="popUpEditName">
            <feather-icon
                icon="Edit2Icon"
                size="16"/>
          </b-button>
        </b-list-group-item>
        <b-list-group-item
            class="d-flex justify-content-between">
          <div>
            <span class="font-weight-bold">
              Nom
            </span>
            <br>
            <span class="font-small-3">
              {{ user.last_name }}
            </span>
          </div>
          <b-button
              class="ml-1 p-50"
              pill
              size="sm"
              variant="flat-primary"
              @click="popUpEditName">
            <feather-icon
                icon="Edit2Icon"
                size="16"/>
          </b-button>
        </b-list-group-item>
        <b-list-group-item
            class="d-flex justify-content-between">
          <div>
            <span class="font-weight-bold">
              Code postal
            </span>
            <br>
            <span class="font-small-3">
              {{ user.zip_code_details.zip_code + ' - ' + user.zip_code_details.name }}
            </span>
          </div>
          <b-button
              class="ml-1 p-50"
              pill
              size="sm"
              variant="flat-primary"
              @click="popUpZipCode">
            <feather-icon
                icon="Edit2Icon"
                size="16"/>
          </b-button>
        </b-list-group-item>
        <b-list-group-item
            class="d-flex justify-content-between">
          <div class="font-weight-bold">
            Mot de passe
          </div>
          <b-button
              class="ml-1 p-50"
              pill
              size="sm"
              variant="flat-primary"
              @click="change_password">
            <feather-icon
                icon="Edit2Icon"
                size="16"/>
          </b-button>
        </b-list-group-item>
        <b-list-group-item
            class="d-flex justify-content-between">
          <div class="font-weight-bold">
            Se déconnecter
          </div>
          <b-button
              class="ml-1 p-50"
              pill
              size="sm"
              variant="flat-warning"
              @click="logout">
            <feather-icon
                icon="LogOutIcon"
                size="16"/>
          </b-button>
        </b-list-group-item>
      </b-list-group>

      <app-collapse>
        <app-collapse-item title="Apparence de l'application">

          <div class="d-flex col-12 justify-content-center p-0">
            <div class="col-5 text-left p-0">
              Original
            </div>
            <b-form-checkbox v-model="keepColor"
                class="col-2 text-center p-0"
                switch
                value=1 @change="checkColors"/>
            <div class="col-5 text-right p-0">
              Adaptative
            </div>
          </div>
        </app-collapse-item>
        <app-collapse-item title="Politique de confidentialité">
          <TextBlock title="Vos données">
            <p class="text-justify">
              Nous recueillons des informations sur quand et comment vous utilisez notre application. Cela nous permet
              d'améliorer la qualité des conseils que nous offrons. Aucune des données personnelles récoltées ne servent
              à la publicité. Ces données ne sont partagées à aucun tiers.
            </p>
          </TextBlock>
          <TextBlock title="Cookies">
            <p class="text-justify">
              Nous utilisons des cookies uniquement pour faciliter votre connexion au site.
            </p>
          </TextBlock>
          <div class="d-flex justify-content-center">
            <b-button
                variant="danger"
                @click="deleteAccountPrompt=true">
              Supprimer mon compte
            </b-button>
          </div>
        </app-collapse-item>
      </app-collapse>
    </div>

    <validation-observer v-slot="{invalid, handleSubmit}">
      <b-modal
          v-model="editNameIsPrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          ok-title="Valider"
          title="Editer votre prénom et nom"
          variant="primary"
          @ok="editName">
        <b-form-group label="Prénom">
          <validation-provider v-slot="{errors}" name="Prénom" rules="max:30">
            <b-form-input v-model="editingFirstName" placeholder="Prénom"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Nom">
          <validation-provider v-slot="{errors}" name="Nom" rules="max:150">
            <b-form-input v-model="editingLastName" placeholder="Nom"/>
            <b-form-text class="text-danger">
              {{ errors[0] }}
            </b-form-text>
          </validation-provider>
        </b-form-group>

        <b-form-text v-if="!validName" class="text-danger">
          Le nom est trop long ou contient des caractères invalides.
        </b-form-text>
      </b-modal>
    </validation-observer>

    <b-modal
        v-model="editZipCodePrompt"
        :ok-disabled="!validZipCode"
        cancel-title="Annuler"
        centered
        ok-title="Valider"
        title="Editer votre code postal"
        variant="primary"
        @ok="editZipCode">

      <b-form-group label="Code postal">
        <b-input-group class="flex-nowrap">
          <b-input-group-prepend is-text>
            <feather-icon icon="HomeIcon"/>
          </b-input-group-prepend>
          <vue-simple-suggest
              v-model="zipCode"
              :debounce="200"
              :list="zipCodeSearch"
              :max-suggestions="limitZipCode"
              :min-length="2"
              :nullable-select="false"
              :prevent-submit="true"
              class="flex-grow-1"
              display-attribute="display"
              placeholder="Code postal"
              value-attribute="id"
              @select="setUpZipCode"/>
        </b-input-group>
        <b-form-text v-if="!validZipCode" class="text-danger">
          Code postal invalide: utilisez les suggestions
        </b-form-text>
      </b-form-group>

    </b-modal>

    <b-modal
        v-model="deleteAccountPrompt"
        :ok-disabled="!passwordExists"
        cancel-title="Annuler"
        centered
        ok-title="Supprimer"
        ok-variant="danger"
        title="Supprimer mon compte"
        variant="danger"
        @ok="deleteAccount">

      <b-alert show variant="danger">
        Êtes-vous certain de vouloir supprimer votre compte ? Une fois supprimé, celui-ci ne pourra plus être
        récupéré.
      </b-alert>

      <b-form-group label="Si oui, renseignez votre mot de passe :">
        <validation-provider v-slot="{errors}" rules="required">

          <b-input-group class="flex-nowrap">
            <b-input-group-prepend is-text>
              <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"/>
            </b-input-group-prepend>
            <b-form-input
                ref="password"
                v-model="password"
                :type="passwordFieldType"
                class="w-full mt-6"
                label-placeholder="Mot de passe"
                name="password"
                placeholder="Mot de passe"/>
          </b-input-group>
          <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
          <b-form-text v-if="passwordError" class="text-danger">
            Mot de passe incorrect.
          </b-form-text>
        </validation-provider>
      </b-form-group>

      <div class="cursor-pointer text-primary" @click="forgotPassword">
        Mot de passe oublié ?
      </div>
    </b-modal>
  </b-modal>
</template>

<script type="text/javascript">
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import {mapState} from "vuex"
import TextBlock from '@/components/global/JayaTextBlock.vue'
import {
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormText,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {max, required} from "vee-validate/dist/rules";
import {togglePasswordVisibility} from '@core/mixins/ui/forms'

extend('required', {...required, message: 'Ce champ est requis'});
extend('max', {...max, message: 'Le champ "{_field_}" doit faire moins de {length} caractères'});

export default {
  name: 'AccountSettings',
  components: {
    VueSimpleSuggest,
    TextBlock,
    BModal,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormCheckbox,
    BFormInput,
    BFormText,
    BFormGroup,
    BFormRadio,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    extend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      editZipCodePrompt: false,
      editNameIsPrompt: false,
      validName: true,
      settingsIsPopUp: false,
      deleteAccountPrompt: false,
      editingFirstName: '',
      editingLastName: '',
      editingZipCode: null,
      zipCode: '',
      limitZipCode: 10,
      password: '',
      passwordError: false,
      keepColor: [],
      activeColor: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
    }),
    passwordExists() {
      return this.password !== ''
    },
    validZipCode() {
      return (this.editingZipCode && this.editingZipCode.id && this.editingZipCode.display === this.zipCode)
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    popUpSettings() {
      if (this.$store.state.account.user.color_adaptation) {
        this.activeColor = 1
        this.keepColor.push(1)
      } else {
        this.activeColor = -1
        this.keepColor.push(-1)
      }

      this.settingsIsPopUp = true
    },
    popUpEditName() {
      this.validName = true
      this.editingFirstName = this.user.first_name
      this.editingLastName = this.user.last_name
      this.editNameIsPrompt = true
    },
    popUpZipCode() {
      this.settingsIsPopUp = true
      this.editZipCodePrompt = true
    },
    editName() {
      this.$store.dispatch('account/updateProfile', {
        first_name: this.editingFirstName,
        last_name: this.editingLastName
      })
          .then(() => {
            this.$loading(false)
            this.editNameIsPrompt = false
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Profil mis à jour",
              text: "Votre nom a bien été mis à jour"
            })
          })
          .catch((error) => {
            this.$loading(false)
            if (error === "invalid_name") {
              this.validName = false
              this.editNameIsPrompt = true
            } else if (error === "server_error") {
              this.editNameIsPrompt = false
              this.$notify({
                time: 4000,
                color: "danger",
                title: "Erreur technique",
                text: "Une erreur est survenue lors de l'édition de vos informations, veillez rééssayer plus tard"
              })
            }
          })
    },
    editZipCode() {
      if (this.validZipCode) {
        this.$loading(true)
        this.$store.dispatch('account/updateProfile', {
          zip_code: this.editingZipCode.id,
        })
            .then(() => {
              this.$store.dispatch('garden/fetchGardens')
                  .then(() => {
                    this.$store.dispatch('localAuthority/fetchSubscription')
                        .then(() => {
                          this.$emit('zip-code-edited')
                          this.$loading(false)
                          this.editZipCodePrompt = false
                        })
                        .catch(() => {
                          this.$loading(false)
                          this.$notify({
                            time: 4000,
                            color: "danger",
                            title: "Erreur technique",
                            text: "Une erreur est survenue lors de la modification du code postal, veuillez recharger votre page"
                          })
                        })
                  })
                  .catch(() => {
                    this.$loading(false)
                    this.$notify({
                      time: 4000,
                      color: "danger",
                      title: "Erreur technique",
                      text: "Une erreur est survenue lors de la modification du code postal, veuillez recharger votre page"
                    })
                  })
            })
            .catch((error) => {
              this.$loading(false)
              if (error === "invalid_zipCode") {
                this.editZipCodePrompt = true
              } else if (error === "tooManyGardens") {
                this.editZipCodePrompt = false
                this.$notify({
                  time: 4000,
                  color: "danger",
                  title: "Nombre de jardins trop important",
                  text: "Vous disposez déjà de 8 jardins, veuillez en supprimer un pour pouvoir mettre à jour votre code postal."
                })
              } else if (error === "server_error") {
                this.editZipCodePrompt = false
                this.$notify({
                  time: 4000,
                  color: "danger",
                  title: "Erreur technique",
                  text: "Une erreur est survenue lors de l'édition de vos informations, veuillez rééssayer plus tard"
                })
              }
            })
      }
    },
    logout() {
      this.$loading(true);
      this.settingsIsPopUp = false
      this.$logout()
          .then(() => {
            this.$loading(false)
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Déconnecté",
              text: "Vous avez bien été déconnecté, à bientôt !"
            })
            this.$router.push({name: 'login'})
          })
          .catch(() => {
            this.$loading(false)
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur technique",
              text: "Une erreur est survenue à la déconnexion, veillez rééssayer plus tard"
            })
          })
    },
    change_password() {
      this.settingsIsPopUp = false
      this.$router.push({name: "change-password"});
    },
    deleteAccount() {
      this.$loading(true)
      let payload = {password: this.password}
      this.$store.dispatch('account/deleteAccount', payload)
          .then(() => {
            this.deleteAccountPrompt = false
            this.settingsIsPopUp = false
            this.$notify({
              title: "Compte supprimé",
              text: "Votre compte a bien été supprimé, en espérant vous revoir très vite au jardin.",
              color: "warning",
              time: 4000
            })
            this.$loading(false)
            this.$router.push({name: "login"})
          })
          .catch(error => {
            this.$loading(false)
            this.deleteAccountPrompt = true
            if (error === "authenticationError") {
              this.passwordError = true
            } else {
              this.$notify({
                title: "Erreur",
                text: "Une erreur est survenue à la suppression de votre compte, veuillez réessayer. Si le problème persiste, veuillez nous contacter.",
                color: "danger",
                time: 4000
              })
            }
          })
    },
    forgotPassword() {
      this.deleteAccountPrompt = false
      this.settingsIsPopUp = false
      this.$router.push({name: "forgot-password"})
    },
    checkColors(event) {
      if (event.length) {
        this.keepColor = event.filter(color => color !== this.activeColor)
        this.activeColor = this.keepColor[0]
      } else {
        this.activeColor = -this.activeColor
        this.keepColor = [this.activeColor]
      }

      let activeGardenLA = this.$store.state.garden.gardens.find(gd => {
        return gd.is_active
      }).local_authority
      let localAuthority = this.$store.state.localAuthority.subscriptions.find(lo => {
        return lo.id === activeGardenLA
      })

      let payload = {"color_adaptation": this.activeColor > 0}
      this.$loading(true)

      this.$store.dispatch('account/updateProfile', payload)
          .then(() => {
            // No change fo baseline
            let color_adaptation = this.activeColor > 0
            let colors = this.$setColors(color_adaptation, localAuthority)
            this.$store.commit("localAuthority/SET_COLOR", colors)
            this.$store.commit("localAuthority/SET_TRANSPENT_HEADER",
                this.$store.state.account.user.color_adaptation && localAuthority &&
                localAuthority.transparent_header)
            // No change for logo
            this.$loading(false)
          })
          .catch(() => {
            this.$loading(false)
          })
    },
    zipCodeSearch(searchText) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("zipCode/search", {search: searchText, limit: this.limitZipCode})
            .then(results => {
              //make name + zipcode attribute to display for user
              resolve(results.data.results.map(obj => ({...obj, display: obj.zip_code + ' - ' + obj.name})))
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    setUpZipCode(zipCode) {
      if (zipCode && zipCode.id) {
        this.editingZipCode = zipCode
      } else {
        this.editingZipCode = null
      }
    }
  },
  watch: {
    '$store.state.localAuthority.subscription'(value) {
      if (value === "#04B587") {
        this.activeColor = -1
        this.keepColor = [-1]
      } else {
        if (this.$store.state.localAuthority.subscriptions != null) {
          this.activeColor = this.$store.state.localAuthority.subscription.id
          this.keepColor = [this.$store.state.localAuthority.subscription.id]
        }
      }
    }
  }
}
</script>

<style lang="scss">
.account-settings {
  .sub-button {
    margin-top: 5px;
  }
}

.vue-simple-suggest.designed .input-wrapper input {
  border: 1px solid #d8d6de !important;
  border-radius: 0 5px 5px 0;
  padding: 0.438rem 1rem;
}

.vue-simple-suggest.designed.focus .input-wrapper input {
  border: 1px solid var(--primary) !important;
}

.vue-simple-suggest ::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}
</style>
