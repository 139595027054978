<template>
  <div v-if="post" id="parentQuestion" class="question-info">
    <!-- Photo de garde -->
    <div class="jaya-top-row">
      <viewer :images="getImage(post)" class="jaya-container">
        <img v-for="src in getImage(post)" :key="src" :src="src" alt="user-upload" class="jaya-image">
      </viewer>

      <div class="jaya-image-buttons">
        <b-button
            v-if="sameUser(post.publisher.id)"
            class="ml-1 p-50"
            pill
            size="sm"
            variant="danger"
            @click="promptDeletePost=true">
          <feather-icon
              icon="TrashIcon"
              size="24"/>
        </b-button>

        <b-button
            v-else
            class="ml-1 p-50"
            pill
            size="sm"
            variant="secondary"
            @click="promptReportContent=true">
          <feather-icon
              icon="AlertOctagonIcon"
              size="24"/>
        </b-button>

        <b-button
            v-if="sameUser(post.publisher.id) && !post.is_closed"
            class="ml-1 p-50"
            pill
            size="sm"
            variant="primary"
            @click="promptClosePost=true">
          <div style="position: relative">
            <feather-icon
                icon="MessageSquareIcon"
                size="24"/>
            <span class="slash-through">/</span>
          </div>
        </b-button>

        <b-button
            v-if="sameUser(post.publisher.id) && post.is_closed"
            class="ml-1 p-50"
            pill
            size="sm"
            variant="danger"
            @click="promptReopenPost=true">
          <div style="position: relative">
            <feather-icon
                icon="MessageSquareIcon"
                size="24"/>
            <span class="slash-through">/</span>
          </div>
        </b-button>
      </div>
    </div>

    <h4 class="m-0 mt-1">{{ post.title }}</h4>

    <div class="w-100 d-flex justify-content-between align-items-center mb-50">
      <div class="text-truncate"><h5> {{ post.plant }} </h5></div>
      <i> {{ getType(post.type) }}</i>
    </div>

    <div v-if="post.details !== ''" class="post-content">
      <div v-for="(part,id) in post.details.split('\n')" :key="id" class="mt-1 text-justify">{{part}}</div>
    </div>

    <jaya-divider :thickness="1" color="dark" style="opacity: 40%;"/>

    <div v-if="!post.is_closed" class="post-comment flex flex-col items-end">
      <label>{{ selectedPlant ? 'Réponse liée à ' + selectedPlant : 'Répondre ' }}</label>

      <b-form-textarea
          id="response-text"
          v-model="answer"
          placeholder="Entrez votre réponse ici"
          rows="3"/>

      <div class="d-flex justify-content-between mt-25">
        <div>
          <b-button
              v-if="selectedPlantId"
              class="ml-1 p-50"
              pill
              size="sm"
              variant="danger"
              @click="newPlantSelect(null)">
            <feather-icon
                icon="TrashIcon"
                size="14"/>
          </b-button>
          <b-button
              v-if="post.type === 'PI'"
              size="sm"
              variant="flat"
              @click="plantSearchCollapse = !plantSearchCollapse">
            <span class="align-self-center">{{
                selectedPlant ? 'Liée à ' + selectedPlant : 'Trouver la plante dans l\'encyclopédie'
              }}</span>
            <feather-icon
                v-if="!plantSearchCollapse"
                icon="ChevronDownIcon"
                size="14"/>
            <feather-icon
                v-else
                icon="ChevronUpIcon"
                size="14"/>
          </b-button>
        </div>
        <b-button
            class="ml-1 p-50"
            variant="primary"
            :disabled="!answer"
            @click="postAnswer">
          Envoyer
        </b-button>
      </div>
      <b-collapse v-model="plantSearchCollapse"
                  class="mt-2">
          <PlantSearch
              :activeFilter="false"
              :preloadContent="false"
              :selectedPlantId="selectedPlantId"
              listType="simple"
              size="150px"
              v-on:newSeleciton="newPlantSelect"/>
      </b-collapse>
    </div>

    <div v-if="post.is_closed" class="text-danger flex justify-center">
      <p> Post clos par l'utilisateur</p>
    </div>

    <div class="d-flex flex-column align-items-end col-12 mt-1 p-0">
      <p v-if="prev !== null" class="cursor-pointer text-primary" @click="loadPreviousAnswers">
        Réponses plus récentes
      </p>
      <b-card
          v-for="(log, key) in getAnswers"
          :key="key"
          :class="sameUser(log.answerer.id) ? 'user-comment': 'not-user-comment'"
          :style="concernedAnswer(log)"
          :title="log.answerer.first_name+' '+log.answerer.last_name.charAt(0)"
          class="col-10 p-0">
        <div class="question-more-options-dropdown">
          <span class="font-small-2 pr-1">{{ getDate(log.created_at) }}</span>
          <b-dropdown
              :id="'answerDD' + key.toString()"
              button-class="p-0"
              class=""
              no-caret
              size="sm"
              variant="flat-gray">
            <template #button-content>
              <feather-icon
                  icon="MoreHorizontalIcon"
                  size="24"/>
            </template>

            <b-dropdown-item
                v-if="log.answerer.id===$store.state.account.user.id"
                @click="deleteAnswer(log.id)">
              Supprimer
            </b-dropdown-item>

            <b-dropdown-item
                v-else
                @click="setUpReport(log.id)">
              Signaler
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="pt-0">
          <div v-for="(part,id) in log.text.split('\n')" :key="id" class="mt-1 text-justify">{{part}}</div>
        </div>

        <div v-if="log.plant" class="d-flex align-items-center justify-content-center">
          <feather-icon
              v-if="log.valid"
              class="text-primary"
              icon="CheckIcon"
              size="10"/>
          <i :class="log.valid ? 'text-primary' : 'text-danger'"> {{ log.plant_name }} aer </i>
        </div>

        <div v-if="sameUser(post.publisher.id)" class="d-flex align-items-center justify-content-end mt-1">
          <b-button
              :variant="log.valid ? 'danger' : 'primary'"
              size="sm"
              @click="validateAnswer(log)">
            <feather-icon
                :icon="log.valid ? 'XCircleIcon' : ''"
                size="14"/>
            <span class="align-center"> {{ log.valid ? null : 'Valider cette réponse' }} </span>
          </b-button>
        </div>
      </b-card>
      <p v-if="this.next" class="cursor-pointer text-primary" @click="loadMoreAnswers">Plus de réponses</p>
    </div>

    <b-modal
        id="deleteQuestion"
        v-model="promptDeletePost"
        cancel-title="Annuler"
        cancel-variant="flat-danger"
        centered
        class="popupActionsQuestion"
        ok-title="Supprimer"
        ok-variant="danger"
        title="Supprimer votre question ?"
        @ok="deletePost">
      <p> Une fois votre question supprimée, vous ne pourrez plus y acceder et les commentaires de celles-ci seront
        supprimés aussi. </p>
    </b-modal>

    <b-modal
        id="reportQuestion"
        v-model="promptReportContent"
        cancel-title="Annuler"
        cancel-variant="flat-danger"
        centered
        class="popupActionsQuestion"
        ok-title="Signaler"
        ok-variant="danger"
        title="Signaler la question ?"
        @ok="reportContent">
      <label>Veuillez décrire en quelques mots le caractère gênant</label>
      <b-form-textarea
          id="report-text"
          v-model="problemDescription"
          rows="3"/>
    </b-modal>

    <b-modal
        id="reportAnswer"
        v-model="promptReportAnswer"
        cancel-title="Annuler"
        cancel-variant="flat-danger"
        centered
        class="popupActionsQuestion"
        ok-title="Signaler"
        ok-variant="danger"
        title="Supprimer la réponse ?"
        @cancel="closeAnswerReport"
        @ok="reportAnswer">
      <label>Veuillez décrire en quelques mots le caractère gênant</label>
      <b-form-textarea
          id="report-answer-text"
          v-model="answerProblemDescription"
          rows="3"/>
    </b-modal>

    <b-modal
        id="closePost"
        v-model="promptClosePost"
        cancel-title="Annuler"
        cancel-variant="flat-danger"
        centered
        class="popupActionsQuestion"
        ok-title="Clore"
        ok-variant="danger"
        title="Clore le post ?"
        @ok="closePost">
      <p> Clore la question empêche les autres utilisateurs d'y répondre. <br> Ils pourront toutefois continuer de voir
        les réponses déjà postées. </p>
    </b-modal>

    <b-modal
        id="reopenPost"
        v-model="promptReopenPost"
        cancel-title="Annuler"
        cancel-variant="flat-danger"
        centered
        class="popupActionsQuestion"
        ok-title="Ré-ouvrir"
        ok-variant="danger"
        title="Ré-ouvrir le post ?"
        @cancel="cancelReopenPost"
        @ok="reopenPost">
      <p> Ré-ouvrir la question permet aux autres utilisateurs de recommencer à y répondre. </p>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  BModal,
  BRow
} from "bootstrap-vue";
import TextBlock from '@/components/global/JayaTextBlock.vue'
import PlantSearch from "@/components/plants/PlantSearch.vue"

export default {
  name: 'QuestionInfo',
  components: {
    BButton,
    BCollapse,
    BCard,
    BCardText,
    BFormTextarea,
    BRow,
    BCol,
    BModal,
    BDropdown,
    BDropdownItem,
    TextBlock,
    PlantSearch
  },
  data() {
    return {
      plantSearchCollapse: false,
      gotPost: false,
      promptReportContent: false,
      promptReportAnswer: false,
      promptClosePost: false,
      promptReopenPost: false,
      problemCategory: '',
      problemDescription: '',
      problematicAnswer: -1,
      answerProblemDescription: '',
      promptDeletePost: false,
      answer: '',
      answers: {},
      gotAnswers: false,
      next: null,
      prev: null,
      offset: 0,
      selectedPlant: '',
      selectedPlantId: null,
    }
  },
  props: {
    post: {
      type: Object,
      default: () => []
    },
    limit: {
      type: Number,
      default: 3
    },
    idAnswerCenterer: {
      type: Number,
      default: -1
    },
    closeDD: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    validAnswer() {
      return this.answer !== '' || this.selectedPlantId
    },
    getAnswers() {
      return this.answers
    }
  },
  methods: {
    getType(type) {
      if (type === "WA") {
        return 'Arrosage'
      } else if (type === "TR") {
        return 'Taille'
      } else if (type === "HA") {
        return 'Récolte'
      } else if (type === "FL") {
        return 'Floraison'
      } else if (type === "PI") {
        return 'Identification de plante'
      }
      return 'Autre'
    },
    close() {
      this.promptDeletePost = false
    },
    closePostReport() {
      this.promptReportContent = false
    },
    closeAnswerReport() {
      this.promptReportAnswer = false
    },
    cancelClosePost() {
      this.promptClosePost = false
    },
    cancelReopenPost() {
      this.promptReopenPost = false
    },
    getImage(post) {
      if (post.photo_url) {
        return [process.env.VUE_APP_BACKEND_URL + '/..' + post.photo_url + "&size=600"]
      } else {
        return [require("@/assets/images/default_pictures/question_default.svg")]
      }
    },
    reportContent() {
      this.$store.dispatch('forum/report', {post: this.post.id, type: this.problemDescription})
          .then(() => {
            this.$notify({
              "title": "Demande prise en compte",
              "text": "Votre demande de signalement sera traitée dans les plus brefs délais",
              "color": "primary",
              "time": 4000
            })
            this.promptReportContent = false
          })
          .catch(() => {
            this.$notify({
              "title": "Erreur",
              "text": "Votre demande de signalement n'a pas fonctionné, veuillez réessayer ultérieurement",
              "color": "danger",
              "time": 4000
            })
          })
    },
    reportAnswer() {
      this.$store.dispatch('forum/report', {answer: this.problematicAnswer, type: this.answerProblemDescription})
          .then(() => {
            this.$notify({
              "title": "Demande prise en compte",
              "text": "Votre demande de signalement sera traitée dans les plus brefs délais",
              "color": "primary",
              "time": 4000
            })
            this.promptReportAnswer = false
          })
          .catch(() => {
            this.$notify({
              "title": "Erreur",
              "text": "Votre demande de signalement n'a pas fonctionnalité, veuillez réessayer ultérieurement",
              "color": "danger",
              "time": 4000
            })
          })
    },
    setUpReport(id) {
      this.promptReportAnswer = true
      this.problematicAnswer = id
    },
    postUserAndDate(str1, str2) {
      if (str2 !== "aujourd'hui") {
        return str1 + " le " + str2
      } else {
        return str1 + " " + str2
      }
    },
    deletePost() {
      this.promptDeletePost = false
      this.$store.dispatch('forum/deleteQuestion', this.post.id)
          .then(() => {
            this.$emit("postDeleted")
          })
          .catch(() => {
            this.$notify({
              title: "Erreur",
              text: "Impossible de supprimer votre question, veuillez réessayer utlérieurement",
              color: "danger",
              time: 4000
            })
          })
    },
    closePost() {
      this.$store.dispatch('forum/updatePost', {id: this.post.id, is_closed: true})
          .then(() => {
            this.post.is_closed = true
            this.$emit('edit-post-status', {close: true})
            this.promptClosePost = false
          })
          .catch(() => {
            this.$notify({
              title: "Erreur",
              text: "Impossible de clore votre question, veuillez réessayer utlérieurement",
              color: "danger",
              time: 4000
            })
          })
    },
    reopenPost() {
      this.$store.dispatch('forum/updatePost', {id: this.post.id, is_closed: false})
          .then(() => {
            this.post.is_closed = false
            this.$emit('edit-post-status', {close: false})
            this.promptReopenPost = false
          })
          .catch(() => {
            this.$notify({
              title: "Erreur",
              text: "Impossible de ré-ouvrir votre question, veuillez réessayer utlérieurement",
              color: "danger",
              time: 4000
            })
          })
    },
    postAnswer() {
      this.plantSearchCollapse = false
      let payload = {
        post: this.post.id,
        text: this.answer,
        plant: this.selectedPlantId
      }
      this.$store.dispatch('forum/postAnswer', payload)
          .then(() => {
            this.answer = ''
            // here we want to get back all the loaded answers including the new one.
            this.$store.dispatch('forum/getAnswers', {
              limit: this.limit + this.offset + 1,
              offset: 0,
              post: this.post.id
            })
                .then(response_ans => {
                  this.answers = response_ans.data.results
                  this.next = response_ans.data.next
                  this.offset += 1
                  this.selectedPlant = ''
                  this.selectedPlantId = null
                })
          })
          .catch(() => {
            this.$notify({
              title: "Erreur",
              text: "Impossible de poster votre réponse",
              color: "danger",
              time: 4000
            })
          })
    },
    getDate(date) {
      let date_obj = new Date(date)
      return date_obj.toLocaleDateString()
    },
    loadMoreAnswers() {
      if (this.next !== null) {
        let payload = {}
        if (this.idAnswerCenterer) {
          payload = {limit: this.limit, offset: this.next, post: this.post.id}
        } else {
          payload = {limit: this.limit, offset: this.offset + this.limit, post: this.post.id}
        }
        this.$store.dispatch('forum/getAnswers', payload)
            .then(response => {
              this.answers = this.answers.concat(response.data.results)
              this.next = response.data.next
              this.offset = this.offset + this.limit
            })
            .catch(() => {
              this.$notify({
                title: "Erreur",
                text: "Impossible de charger plus de réponses",
                color: "danger",
                time: 4000
              })
            })
      }
    },
    loadPreviousAnswers() {
      if (this.prev !== null) {
        let payload = {}
        if (this.idAnswerCenterer) {
          payload = {limit: this.limit, offset: this.prev, post: this.post.id}
        } else {
          payload = {limit: this.limit, offset: this.offset + this.limit, post: this.post.id}
        }
        this.$store.dispatch('forum/getAnswers', payload)
            .then(response => {
              // when the loaded answers are the previous ones, they should be added at the start of the list
              this.answers = response.data.results.concat(this.answers)
              this.offset = this.offset + this.limit
              if (this.idAnswerCenterer) {
                this.prev = response.data.previous
              }
            })
            .catch(() => {
              this.$notify({
                title: "Erreur",
                text: "Impossible de charger plus de réponses",
                color: "danger",
                time: 4000
              })
            })
      }
    },
    deleteAnswer(id) {
      /*
      Delete the answer and update the list without recalling the api
      */
      this.$store.dispatch('forum/deleteAnswer', id)
          .then(() => {
            this.answers = this.answers.filter(function (el) {
              return el.id !== id
            })
          })
          .catch(() => {
            this.$notify({
              title: "Erreur",
              text: "Nous n'avons pas pu supprimer votre réponse, veuillez réessayer plus tard",
              color: "danger",
              time: 4000
            })
          })
    },
    sameUser(userId) {
      // check the user is not null (for instance when logout)
      if (this.$store.state.account.user !== null) {
        if (this.$store.state.account.user.id === userId) {
          return true
        }
      }
      return false
    },
    concernedAnswer(log) {
      if (log.id === this.idAnswerCenterer) {
        return {'border-color': "var(--primary)"}
      }
      if (log.valid) {
        return {'border-color': "var(--primary)"}
      }
    },

    // Plant selection
    newPlantSelect(value) {
      if (!value) {
        this.selectedPlant = ''
        this.selectedPlantId = null
        this.plantSearchCollapse = false
      } else {
        this.selectedPlant = value.common_name
        this.selectedPlantId = value.id
        this.plantSearchCollapse = false
      }
    },
    validateAnswer(log) {
      let payload = {id: this.post.id, valid_answer: log.valid ? null : log.id}
      this.$store.dispatch('forum/updatePost', payload)
          .then(() => {
            let validated = false
            for (let answer of this.answers) {
              if (answer.id !== log.id || log.valid) {
                answer.valid = false
              } else {
                validated = true
                answer.valid = true
              }
            }
            this.$emit('edit-post-status', {valid: validated})
          })
          .catch(() => {
            this.$notify({
              title: "Impossible de valider",
              color: "danger",
              text: "Une erreur est survenue lors de la validation de la réponse, veuillez réssayer ultérieurement",
              time: 4000
            })
          })
    }
  },
  watch: {
    post: {
      immediate: true,
      handler(value) {
        if (value) {
          let payload = {limit: this.limit, post: value.id}
          if (this.idAnswerCenterer >= 0) {
            payload.center = this.idAnswerCenterer
          }
          this.$store.dispatch('forum/getAnswers', payload)
              .then(response => {
                this.answers = response.data.results
                this.next = response.data.next
                if (this.idAnswerCenterer >= 0) {
                  this.prev = response.data.previous
                }
              })
        }
      }
    },
    // todo: manage emits
    closeDD(value) {
      if (value) {
        if (this.$refs.answerDropdown) {
          let activesDDowns = this.$refs.answerDropdown.filter((item) => {
            return item.vsDropdownVisible === true
          })
          for (let active of activesDDowns) {
            active.vsDropdownVisible = false
          }
          let activesDDownsMenu = this.$refs.answerDropdownMenu.filter((item) => {
            return item.dropdownVisible === true
          })
          for (let activeMenu of activesDDownsMenu) {
            activeMenu.dropdownVisible = false
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.question-info {
  height: 100%;

  .user-comment {
    border-radius: 20px 20px 0px 20px;
    align-self: end;

    .card-title {
      color: var(--primary) !important;
    }
  }

  .not-user-comment {
    border-radius: 20px 20px 20px 0px;
    align-self: start;
  }

  .slash-through {
    position: absolute;
    font-weight: 700;
    font-size: 24px;
    left: 7px;
    top: 0;
  }

  .card-title {
    margin-bottom: 5px !important;
  }

  .question-more-options-dropdown {
    position: absolute;
    top: 5px;
    right: 15px;

    .dropdown-toggle {
      padding: 0;
    }

    .dropdown-menu {
      padding: 0;
      margin: 0;
    }
  }

  .card {
    box-shadow: none;
    border: solid 1px var(--dark);
    background-color: var(--light);
  }

}
</style>
