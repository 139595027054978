<template>
  <div
      class="vGarden-container"
      >
    <canvas :id="'vGarden-canvas-' + id"/>
    <div
        v-if="activePlant && feedback"
        :key="feedBackKey"
        :style="absolutePosition(activePlant.x * this.scale,activePlant.y * this.scale)"
        class="topButtons">

      <b-form-input
          v-model="feedBackQuantityResults[activePlant.id]"
          class="mb-25"
          min="0"
          step="1"
          style="width: 100px;"
          type="number"
          @input="emitFeedback"/>

      <div class="d-flex justify-content-center align-items-center bg-secondary rounded-lg">
        <b-button
           :variant="feedBackQualityResults[activePlant.id] ===  -2? 'flat-primary' : 'flat-secondary;'"
            class="p-0"
            @click="setQuality(activePlant.id, -2)"
        >
          <feather-icon
              class="cursor-pointer"
              icon="FrownIcon"
              size="24"/>
        </b-button>

        <b-button
            :variant="feedBackQualityResults[activePlant.id] ===  0? 'flat-primary' : 'flat-secondary;'"
            class="p-0"
            @click="setQuality(activePlant.id, 0)"
        >
          <feather-icon
              class="cursor-pointer"
              icon="MehIcon"
              size="24"/>
        </b-button>

        <b-button
            :variant="feedBackQualityResults[activePlant.id] ===  2? 'flat-primary' : 'flat-secondary;'"
            class="p-0"
            @click="setQuality(activePlant.id, 2)"
        >
          <feather-icon
              class="cursor-pointer"
              icon="SmileIcon"
              size="24"/>
        </b-button>
      </div>
    </div>

    <div
        v-if="activePlant && !feedback && !moving"
        :style="absolutePosition(activePlant.x * this.scale,activePlant.y * this.scale)"
        class="topButtons">
  <div class="position-relative">
    <b-button
        pill
        id="openActivePlantButton"
        variant="primary"
        class="btn-icon position-absolute position-bottom-0 position-right-0"
        @click="$emit('openPlantSettings', activePlant)"
    >
      <feather-icon icon="EyeIcon" />
    </b-button>
  </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import fabricModule from 'fabric'
import {useCssVar} from "@vueuse/core";
import {BFormInput, BButton} from "bootstrap-vue";

const fabric = fabricModule.fabric

export default {
  name: "vGarden-canvas",
  components: {
    BFormInput,
    BButton,
  },
  props: {
    vGarden: {
      type: Object,
      required: true
    },
    xLength: {
      type: Number,
      default: 300
    },
    yLength: {
      type: Number,
      default: 200
    },
    initialSetUp: {
      type: Boolean,
      default: false
    },
    feedback: {
      type: Boolean,
      default: false
    },
    initialSetup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: null,
      canvas: null,
      plants: [],
      activePlant: null,
      moving: false,
      scale: 100,
      feedBackQuantityResults: [],
      feedBackKey: 1,
      feedBackQualityResults: [],
      emittedUpdate: false
    }
  },
  created() {
    // Get the component uniq id
    this.id = this._uid
  },
  mounted() {
    //Feedback Input base
    for (const plant of this.vGarden.vgarden_plants) {
      this.feedBackQuantityResults[plant.id] = 0
    }

    // Fix hot reload issue
    if (this.canvas != null) {
      return
    }

    // Create Canvas
    this.canvas = new fabric.Canvas("vGarden-canvas-" + this.id)
    this.canvas.selection = false

    //make background
    var background = new fabric.Rect({
      selectable: false,
      hasControls: false,
      hoverCursor: 'default',
      top: this.borderSize,
      left: this.borderSize,
      fill: "rgb(181,136,118)",
      strokeWidth: 0,
      height: this.vGarden.width * this.scale,
      width: this.vGarden.length * this.scale
    })
    this.canvas.add(background)

    var border = new fabric.Rect({
      selectable: false,
      hasControls: false,
      hoverCursor: 'default',
      top: 0,
      left: 0,
      fill: null,
      stroke: "rgb(234, 178, 122)",
      strokeWidth: this.borderSize,
      rx: 0.04 * this.scale,
      ry: 0.04 * this.scale,
      height: this.vGarden.width * this.scale + this.borderSize,
      width: this.vGarden.length * this.scale + this.borderSize
    })
    this.canvas.add(border)

    //create rulers
    var tic = 0
    for (let i = 0.1 * this.scale, counter = 1; i <= this.vGarden.length * this.scale; i = i + 0.1 * this.scale, counter++) {
      tic = 0.01 * this.scale
      if (counter % 5 === 0) {
        tic = 0.02 * this.scale
      }
      if (counter % 10 === 0) {
        tic = 0.04 * this.scale
      }
      //horizontal
      this.canvas.add(new fabric.Rect({
        selectable: false,
        hasControls: false,
        hoverCursor: 'default',
        top: this.borderSize,
        left: i,
        height: tic,
        width: 0.15 * tic
      }))
      //vertical
      if (i / 100 <= this.vGarden.width) {
        this.canvas.add(new fabric.Rect({
          selectable: false,
          hasControls: false,
          hoverCursor: 'default',
          top: i,
          left: this.borderSize,
          height: 0.15 * tic,
          width: tic
        }))
      }
    }

    this.canvas.on("object:moving", e => {
      // Update plant rendering while moving
      if (e.target.type === "plantGroup") {
        this.updatePlantPosition(e.target)
      }
    })

    this.canvas.on("object:moved", e => {
      // Update plant position in store
      if (e.target.type === "plantGroup") {
        let plantGroup = e.target
        let plantIndex = this.vGarden.vgarden_plants.findIndex(plant => plant.id === plantGroup.plantObject.id)
        if (plantIndex === -1 || plantIndex === null || plantIndex === undefined) {
          this.activePlant = null
        } else {
          let payload = {
            x: (plantGroup.left-this.borderSize) / this.scale,
            y: (plantGroup.top-this.borderSize) / this.scale,
            vegetable_garden: this.vGarden.id,
            index: this.vGarden.vgarden_plants[plantIndex].id,
            index_store: plantIndex,
          }
          this.$store.dispatch('garden/updateVGardenPlant', payload)
              .then(() => {
                if (this.initialSetUp) {
                  this.$emit('plant-moved')
                  this.emittedUpdate = true
                }
              })
              .catch(() => {
                this.$notify({
                  color: "danger",
                  title: "Erreur",
                  text: "Impossible d'enregistrer la position de la plante",
                  time: 4000
                })
              })
        }
      }
    })

    this.canvas.on('mouse:down', e => {
      this.moving = true
      if (e.target != null && e.target.type === 'plantGroup') {
        this.activePlant = e.target.plantObject
      } else {
        this.activePlant = null
      }

      this.plants.forEach(plant => {
        this.updatePlantAppearance(plant, e.target)
      })
    })

    this.updatePlants()
  },
  computed: {
    borderSize() {
      return 0.03 * this.scale
    },
    canvasX() {
      return this.xLength - 2 * this.borderSize
    },
    canvasY() {
      return this.yLength - 2 * this.borderSize
    },
    scaleX() {
      return this.canvasX / (this.vGarden.length * this.scale + 2 * this.borderSize)
    },
    scaleY() {
      return this.canvasY / (this.vGarden.width * this.scale + 2 * this.borderSize)
    }
  },
  methods: {
    updatePlants() {
      // As we cannot know witch plant has been updated, we recreate all the canvas elements
      this.plants.map(plant => plant.plantGroup).forEach(plantGroup => {
        this.canvas.remove(plantGroup)
      })
      this.plants = []
      let plantRadiusArray = this.vGarden.vgarden_plants.map(({id, garden_plant}) => {
        return {
          radius: garden_plant.plant.jaya_infos.planting_radius ? garden_plant.plant.jaya_infos.planting_radius : 0.5,
          id: id,
        }
      })
      plantRadiusArray = plantRadiusArray.sort((a, b) => {
          return b.radius - a.radius;
      })
      for (const radiusPlant of plantRadiusArray) {
        let plant = this.vGarden.vgarden_plants.find(plant => plant.id === radiusPlant.id)
        let this_ = this
        let picto = require("@/assets/images/icons/plants/default_leaf.svg")
        if (plant.garden_plant.plant.pictos[0].name) {
          picto = require("@/assets/images/icons/plants/" + plant.garden_plant.plant.pictos[0].name)
        }

        fabric.loadSVGFromURL(picto, function(objects, options) {
          let plantCircles = []
          let nX = 1
          let nY = 1
          if (plant.pattern && plant.pattern.type === "rectangle") {
            nX = Math.floor(plant.pattern.size_x/plant.pattern.spacing_x) + 1
            nY = Math.floor(plant.pattern.size_y/plant.pattern.spacing_y) + 1

            for (let i=0; i<nX; i++) {
              for (let j=0; j<nY; j++) {
                plantCircles.push(
                    new fabric.Circle({
                      originX: 'center',
                      originY: 'center',
                      left: i * plant.pattern.spacing_x * this_.scale,
                      top: j * plant.pattern.spacing_y * this_.scale,
                      radius: plant.garden_plant.plant.jaya_infos ? plant.garden_plant.plant.jaya_infos.planting_radius * this_.scale : 0.5 * this_.scale,
                      fill: '#000000',
                      strokeWidth: 0,
                    })
                )
              }
            }
          } else {
            plantCircles.push(
                new fabric.Circle({
                  originX: 'center',
                  originY: 'center',
                  left: 0,
                  top: 0,
                  radius: plant.garden_plant.plant.jaya_infos ? plant.garden_plant.plant.jaya_infos.planting_radius * this_.scale : 0.5 * this_.scale,
                  fill: '#000000',
                  strokeWidth: 0,
                })
            )
          }

          // Plant Circle
          let plantCircle = new fabric.Group(plantCircles, {
            originX: 'center',
            originY: 'center',
            left: 0,
            top: 0,
          })

          let delta_x = 0
          let delta_y = 0
          if (plant.pattern && plant.pattern.type === "rectangle") {
            delta_x = (nX%2-1)/2 * plant.pattern.spacing_x * this_.scale
            delta_y = (nY%2-1)/2 * plant.pattern.spacing_y * this_.scale
          }


          let plantPicto = fabric.util.groupSVGElements(objects, options);
          plantPicto.scaleToWidth(0.1 * this_.scale);
          plantPicto.scaleToHeight(0.1 * this_.scale);
          plantPicto.set({
            originX: 'center',
            originY: 'center',
            left: delta_x,
            top: delta_y,
          })

          let plantGroup = new fabric.Group([plantPicto, plantCircle], {
            type: "plantGroup",
            plantCircle: plantCircle,
            plantObject: plant,
            plantPicto: plantPicto,
            selectable: !plant.fixed && !this_.feedback,
            originX: 'center',
            originY: 'center',
            left: plant.x * this_.scale + this_.borderSize,
            top: plant.y * this_.scale + this_.borderSize,
            perPixelTargetFind: true,
            hasControls: false,
            hasBorders: false,
            moveCursor: 'grabbing',
            hoverCursor: 'grab',
            objectCaching: false, // Fix invisible large text and blurry render
          })

          this_.canvas.add(plantGroup)

          this_.plants.push({
            plantObject: plant,
            plantCircle: plantCircle,
            plantGroup: plantGroup,
            plantPicto: plantPicto,
          })

          this_.updatePlantAppearance(plantGroup)
        })
      }
      this.updateDimension()
    },
    updateDimension() {
      // Set canvas dimension
      this.canvas.setWidth(this.canvasX)
      this.canvas.setHeight(this.canvasY)
      // Update transformation matrix
      this.canvas.setViewportTransform([
        this.scaleX,
        0,
        0,
        this.scaleY,
        0,
        0
      ])
      // Update elements size
      this.plants.forEach(plant => {
        this.updatePlantPosition(plant.plantGroup)
      })

      // Repaint canvas
      this.canvas.renderAll()
      this.canvas.calcOffset()
    },
    updatePlantPosition(plantGroup) {
      const width = plantGroup.width - 2*plantGroup.plantObject.garden_plant.plant.jaya_infos.planting_radius * this.scale
      const height = plantGroup.height - 2*plantGroup.plantObject.garden_plant.plant.jaya_infos.planting_radius * this.scale

      if (plantGroup.left > this.vGarden.length * this.scale + this.borderSize - width/2) {
        plantGroup.set({left: this.vGarden.length * this.scale + this.borderSize - width/2,})
      }
      if (plantGroup.left < this.borderSize + width/2) {
        plantGroup.set({left: this.borderSize + width/2,})
      }
      if (plantGroup.top > this.vGarden.width * this.scale + this.borderSize -  height/2) {
        plantGroup.set({top: this.vGarden.width * this.scale + this.borderSize -  height/2,})
      }
      if (plantGroup.top < this.borderSize + height/2) {
        plantGroup.set({top: this.borderSize + height/2,})
      }
    },
    updatePlantAppearance(plant) {
      let color = '#FFFFFF'
      let opacity ='4D'
      let borderColor = color
      let borderWidth = 0

      //change color depending on state
      if (plant.plantObject.current_state === 'PL') {
        color = useCssVar(`--secondary`, document.documentElement).value.trim()
      }

      //highest opacity for active Plant
      if (this.activePlant && plant.plantObject.id === this.activePlant.id) {
        opacity = 'B2'

        //change border depending on fixed status
        borderWidth = 0.004 * this.scale
        if (this.activePlant.fixed) {
          borderColor = useCssVar(`--danger`, document.documentElement).value.trim()
        } else {
          borderColor = useCssVar(`--primary`, document.documentElement).value.trim()
        }
      }

      //medium opacity for plant of same variety
      if (this.activePlant && plant.plantObject.id !== this.activePlant.id && plant.plantObject.garden_plant.plant.id === this.activePlant.garden_plant.plant.id) {
        opacity = '7F'
      }

      plant.plantCircle.forEachObject((obj) => {
        obj.set('fill', color + opacity)
        obj.set('stroke',  borderColor)
        obj.set('strokeWidth', borderWidth)
      })


      this.moving = false
    },
    absolutePosition(x, y) {
      if (this.canvas) {
        //make sure input object is inbound
        let xMargin = (140 / 2) / this.scaleX
        let yMargin = (80 / 2) / this.scaleY

        if (x < xMargin) {
          x = xMargin
        } else if (x > this.vGarden.length * this.scale - xMargin) {
          x = this.vGarden.length * this.scale - xMargin
        }
        if (y < yMargin) {
          y = yMargin
        } else if (y > this.vGarden.width * this.scale - yMargin) {
          y = this.vGarden.width * this.scale - yMargin
        }

        let newPoint = fabric.util.transformPoint({x, y}, this.canvas.viewportTransform)

        return 'left: ' + newPoint.x + 'px;top: ' + newPoint.y + 'px;'
      }
      return ''
    },
    setQuality(plantId, quality) {
      this.feedBackQualityResults[plantId] = quality
      this.feedBackKey = this.feedBackKey + 1
      this.emitFeedback()
    },
    emitFeedback() {
      let payload = {
        quantity: this.feedBackQuantityResults,
        quality: this.feedBackQualityResults
      }
      this.$emit('feedback', payload)
    },
  },
  watch: {
    vGarden: {
      handler: function () {
        this.updatePlants()
      },
      deep: true
    },
    xLength() {
      this.updateDimension()
    },
    yLength() {
      this.updateDimension()
    },
    activePlant(val) {
      if (val) {
        this.$emit('newActivePlant', val)
        //show action button
      } else {
        this.$emit('newActivePlant', null)
      }
    }
  }
}
</script>

<style lang="scss">
.vGarden-container {
  position: relative;
  overflow: hidden;
  font-size: 16px !important;


  .topButtons {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .satisfaction-icon {
    height: 20px;
    width: 20px;
    border: 2px solid gray;
    border-radius: 100%;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
  }

  .border-primary {
    border-color: var(--primary);
  }
}
</style>

